<template>
<!-- Pause Screen -->
  <div v-show="pause_game && show_pause_screen" id="PauseScreen" class="w-full h-full m-auto bg-black bg-opacity-50 z-50 select-none absolute">
    <div class="flex flex-col w-full h-full justify-center items-center">
      <p class="thambi-font-bold text-9xl text-white">GAME PAUSED</p>
      <br>
    </div>
  </div>
<!-- /Pause Screen -->
<div class="w-full  border-purple-400 rounded-xl relative flex flex-col flex-grow sm:flex-row sm:justify-center">
  <div class="relative content-center 2xl:content-start flex-grow-0 2xl:flex-grow-0 w-70p my-auto mx-auto 2xl:mt-2 items-center bg-cpc-grey-700" id="board_wrapper">
    <div id="board1" class="w-full h-full rounded-xl scooped flex justify-center">Chessboard Go BRRRR, try refreshing! 🤷‍♀️</div>
  </div>
</div>
</template>

<script>
const Chess = require("chess.js");
import ChessBoard from "chessboardjs-vue";
import $ from 'jquery';
import { ref, watch, toRefs, onMounted } from 'vue';

export default {
    emits: ['queue-move'],
    props: {
        gameState: Object,
        countdownTimer: Number,
        flipped: Boolean,
        boardOrientation: String,
        pauseGame: Boolean,
    },
    setup(props){
        let app = this;
        let whiteSquareGrey = '#fbfb98';
        let blackSquareGrey = '#e2e438';
        let squareClass = 'square-55d63';
        let highlight_legal = ref(true);
        let highlight_move = ref(true);
        let game_state = ref({});
        let flipped_val = ref(props.flipped);
        let boardOrientation_val = ref(props.boardOrientation);
        let move_made = false;
        let pause_game = ref(props.pauseGame);
        let show_pause_screen = ref(true);

        let config = {
            draggable: true,
            position:  'start',
            orientation: boardOrientation_val.value,
            dropOffBoard: 'snapback',
        }
        let countdown_timer = ref(props.countdownTimer);

        //const game_state_watch_wrapper = ref(props.gameState);

        let pause_chat_game_clock = ref(false);

        //var gameStateRef = toRefs(props).gameState;
        var boardOrientationRef = toRefs(props).boardOrientation;

        watch(boardOrientationRef, (newVal, oldVal) => {
            console.log('watch: boardOrientation prop was changed', oldVal, newVal);
        })
        /*
        watch(gameStateRef, (newVal, oldVal) => {
            console.log('watch: gameState prop was changed');
            //this.updateStatus();
        });
        */
        watch(countdown_timer, function(newVal, oldVal) {
            console.log('watch: countdowntimer prop was changed', newVal);
        })

        let pauseGameRef = toRefs(props).pauseGame;
        watch(pauseGameRef, function(newVal, oldVal){
            console.log('watch: pausegame prop was changed', newVal);
            PauseGameFunc(newVal);
        })

        function PauseGameFunc(show_screen){
            pause_game.value = show_screen;
        }

        return {
            whiteSquareGrey,
            blackSquareGrey,
            squareClass,
            highlight_legal,
            highlight_move,
            game_state,
            pause_chat_game_clock,
            flipped_val,
            boardOrientation_val,
            config,
            move_made,
            countdown_timer,
            pause_game,
            show_pause_screen,
            //game_state_watch_wrapper,
        }
    },
    updated(){
        console.log("Chessboard updated");
        console.log('Checking values', this.pause_game, this.show_pause_screen);
    },
    mounted(){
        let app = this;
        //Chess.js/Chessboard.js functions
        let board = null
        let $board = $("#board1");
        let squareToHighlight = null;
        var game = new Chess();
        var $status = $('#status')
        var $fen = $('#fen')
        var $pgn = $('#pgn')
        let chat_turn;
        let move_votes = app.gameState.votes === null || undefined ? {} : app.gameState.votes;
        let streamername = this.$route.params.streamername.toLowerCase();
        let web_captain_id = localStorage.getItem('web_captain_id');
        let timerObj = document.getElementById('new_timer');
        let display = document.querySelector('#time');

        let countdown;
        let start_chat_voting_timer;
        let startChatVoting_wrapper;

        var mPlayer;
        var mColor;

        function removeGreySquares () {
            $('#board1 .square-55d63').css('background', '')
        }

        function greySquare (square) {
            var $square = $('#board1 .square-' + square)

            var background = app.whiteSquareGrey
            if ($square.hasClass('black-3c85d')) {
                background = app.blackSquareGrey
            }

            $square.css('background', background)
        }

        function removeHighlights (color) {
            $board.find('.' + app.squareClass)
                .removeClass('highlight-' + color)
        }

        function onMouseoverSquare (square, piece) {
            // get list of possible moves for this square
            var moves = game.moves({
                square: square,
                verbose: true
            })

            // exit if there are no moves available for this square
            if (moves.length === 0) return

            if(app.highlight_legal){
                // highlight the square they moused over
                greySquare(square)      

                // highlight the possible squares for this piece
                for (var i = 0; i < moves.length; i++) {
                greySquare(moves[i].to)
                }
            }
        }

        function onMouseoutSquare (square, piece) {
            if(app.highlight_legal){
                removeGreySquares();
            }
        }

        function onDragStart (source, piece, position, orientation) {
            // viewer do not pick up piece if its not your turn.
            if(!chat_turn) return false;
            
            // do not pick up pieces if the game is over
            if (game.game_over()) return false

            // only pick up pieces for the side to move
            if ((game.turn() === 'w' && piece.search(/^b/) !== -1) ||
                (game.turn() === 'b' && piece.search(/^w/) !== -1)) {
                return false
            }
        }

        function onDrop (source, target) {
            if(app.highlight_legal){
                removeGreySquares();
            }

            // see if the move is legal
            var move = game.move({
                from: source,
                to: target,
                promotion: 'q' // NOTE: always promote to a queen for example simplicity
            })

            // illegal move
            if (move === null) return 'snapback'

            if(app.highlight_move){
                // highlight white's move
                removeHighlights('white')
                $board.find('.square-' + source).addClass('highlight-white')
                $board.find('.square-' + target).addClass('highlight-white')
            }

            updateStatus()
        }

        function onMoveEnd () {
            $board.find('.square-' + squareToHighlight)
                .addClass('highlight-black');
        }
        // update the board position after the piece snap
        // for castling, en passant, pawn promotion
        const onSnapEnd = (source, target, piece) => {
            board.position(game.fen())
            //console.log(game.history())
            app.move_made = true;
            setChatTimerZero();
            this.$emit('queue-move',`${source}-${target}`, streamername, web_captain_id);
        }

        function updateStatus() {
            app.move_made = false;
            console.log('updateStatus ran chessboard.', app.flipped_val);
            var status = ''

            var moveColor = 'White';
            mColor = moveColor;
            var movePlayer;

            if(app.flipped_val){
                chat_turn = true;
                movePlayer = mPlayer = 'Chat';
            }else{
                movePlayer = mPlayer = 'Streamer';
                chat_turn = false;
                move_votes = {};
            }
            
            if(app.pause_game === true){
                console.log('app.pause_game is true, going to pause');
                PauseGame(true);
            }

            if (game.turn() === 'b') {
                moveColor = 'Black';
                mColor = moveColor;

                if(app.flipped_val){
                    movePlayer = mPlayer = 'Streamer';
                    chat_turn = false;
                    move_votes = {};
                }else{
                    movePlayer = mPlayer = 'Chat';
                    chat_turn = true;     
                }  
            }

            //console.log('updateStatus check', chat_turn, game.turn())

            if(chat_turn && !app.pause_game){
                //ExecuteChatSide();
            }else{
                setChatTimerZero();
            }

            // checkmate?
            if (game.in_checkmate()) {
                status = 'Game over, ' + moveColor + ' is in checkmate.';
                app.gamestatus = 'Game over, ' + moveColor + ' is in checkmate.';
                app.win_modal_icon = "/imgs/win_trophy.json"
                app.winModal = "block";
            }

            // draw?
            else if (game.in_draw()) {
                status = 'Game over, drawn position'
                app.gamestatus = "Game over, The match ended in stalemate! It's a draw."
                app.win_modal_icon = "/imgs/draw_swords.json"
                app.winModal = "block";
            }

            // game still on
            else {
                status = moveColor + ` (${mPlayer}) ` + ' to move'

                // check?
                if (game.in_check()) {
                status += ', ' + moveColor + ' is in check'
                }
            }

            $status.html(status)
            $fen.html(game.fen())

            let pgn_raw = game.pgn();
            const regex = /(\w+|\+)([^.])(\d+)(\.)/g;
            const subst = `$1$2<br>$3$4`;
            
            let altered_moves = pgn_raw.replace(regex, subst);
            $pgn.html(altered_moves);
        }

        function returnConfig(flipped){
            app.config.onDragStart=onDragStart;
            app.config.onDrop=onDrop;
            app.config.onMouseoutSquare=onMouseoutSquare;
            app.config.onMouseoverSquare=onMouseoverSquare;
            app.config.onSnapEnd=onSnapEnd;
            app.config.onMoveEnd=onMoveEnd;

            return app.config;
        }

        function setBoardHeightDynamically(){
            let b = document.getElementById('board1');
            console.log('setting height dynamically', b.clientWidth + "px");
            document.getElementById('board_wrapper').style.height = b.clientWidth + "px";
        }

        const ExecuteChatSide = () => {
            console.log('ExecutingChatSide');
            clearInterval(countdown);
            countdown = null;

            startChatVoting_wrapper = startChatVoting(app.countdown_timer, game).then((res) => {
                console.log('chat timer started', app.countdown_timer, res);
                if(res == 'done'){
                    setChatTimerZero();
                    updateStatus(); //be careful with this
                }
            })
        }

        const setChatTimerZero = () => {
            console.log('clearing out chat timer');
            clearInterval(countdown);
            countdown = null;
            clearTimeout(start_chat_voting_timer);
            start_chat_voting_timer = null;
            let display = document.querySelector('#time');
            display.textContent = "00";
            timerObj.style.width = '0%';
        }

        function startChatVoting(milliseconds, game){
            console.log('startChatVoting running');
            setChatTimerZero();
            if(!game.in_checkmate() && !game.in_draw()){ //if the game is not in checkmate and the game is not in draw vote it up and if the game isn't over
                if (!milliseconds) milliseconds = 15000; //default of 15 seconds if one isnt supplied or in case of a fail to get the user set value 
                startTimer(milliseconds / 1000);
                if(app.move_made){
                    return new Promise(resolve => { 
                        start_chat_voting_timer = setTimeout(resolve, 1, 'move_made')
                    });
                }else{
                    return new Promise(resolve => { 
                        start_chat_voting_timer = setTimeout(resolve, milliseconds, 'done')
                    });
                }
            }else{
                return new Promise(resolve => start_chat_voting_timer = setTimeout(resolve, 1, 'done 2'));
            }
        }

        function startTimer(duration){
            console.log('startTimer running');
            
            let timer = duration, seconds;
            if((countdown === null || !countdown)){
                countdown = setInterval(function(){
                    seconds = parseInt(timer);
                    seconds = seconds < 10 ? "0" + seconds : seconds;
                    display.textContent = seconds;
                    //console.log('seconds left are', seconds, timer);
                    let percentageDiff = (seconds / duration) * 100;

                    timerObj.style.width = percentageDiff + '%';
                    if(--timer < 0){
                        timer = duration;
                        clearInterval(countdown)
                        countdown = null;
                        //console.log('in here like swimwear');
                        //updateStatus(); //removing this just in case? 
                    }
                }, 1000)
            }
        }

        const PauseGame = (show_screen) => {
            app.pause_game = show_screen;
            app.show_pause_screen = show_screen;
            if(app.pause_game){
                console.log("pause is running")
                clearInterval(countdown);
                countdown = null;
                clearTimeout(start_chat_voting_timer);
                start_chat_voting_timer = null;
                startChatVoting_wrapper = null;
                setChatTimerZero();
            }else{
                console.log('unpause is running');
                app.show_pause_screen = false;
                if(chat_turn){
                    clearInterval(countdown);
                    countdown = null;
                    startChatVoting_wrapper = null;
                    //updateStatus();
                    UnsetPauseChatGameClock();
                    ExecuteChatSide(mColor, mPlayer);
                }else{
                    console.log('updateStatus: 1442')
                    updateStatus();
                }
            }
        }

        initializeBoard();
       
        function initializeBoard(){
            console.log('function initializeBoard()')
            let config = returnConfig(app.flipped_val);
            board = ChessBoard('board1', config);
            game.load(app.gameState.fen_string); //set game state
            board.position(app.gameState.fen_string); //set board state
            setBoardHeightDynamically();
        }
        
        $(window).resize(function() {
            setBoardHeightDynamically();
        })

        $(window).ready(function(){
            setBoardHeightDynamically();
        });

        try{ 
            document.getElementsByClassName('chessboard-63f37')[0].classList.add('m-auto');
        }catch(e){
            console.log(e);
        }
        
        updateStatus();
    },
    methods: {
    }
}
</script>

<style scoped>
    #board1 :deep(.white-1e1d7) {
        background-color: #646372;
        @apply text-purple-50;
    }
    #board1 :deep(.black-3c85d) {
        background-color: #353340;
        @apply text-purple-50;
    }
    #board1 :deep(.notation-322f9) {
        @apply text-2xl;
        font-family: 'Baloo Thambi';
        font-weight: 700;
    }
    .w-70p{ 
        width: 85%;
    }
</style>
