<template>
    <button type="button" :class="color" class="bg-gradient-to-br from-cpc-cyan-50 to-cpc-cyan-100 place-self-center sm:float-right px-2 py-1 sm:flex  rounded-lg focus:outline-none hover:text-white text-white text-xl">
      <div class="my-auto flex flex-row h-full items-center justify-center">
        <slot></slot>
        <span v-if="text"  class="text-base sm:text-lg fredokaone-font text-purple-900">{{text}}</span>
        <!-- :class="{'pl-3 sm:pl-3': this.$slots}" -->
      </div>
    </button>
</template>

<script>
export default {
    props:{
        text: String,
        color: String,
    }
}
</script>

<style scoped>
    .p-gradient {
        background: linear-gradient(152.29deg, #8133FF 17.22%, #6B2185 146.62%);
    }
    .game-button-shadow{
        box-shadow: 0 -5px 0 0 #cbafff00, 0 6px 0 0 #1F1D2B, 0 19px 0 0 rgb(0 0 0 / 0%);
    }
</style>