<template>
    <div
        :class="showing"
        class="px-10 py-5 bg-purple-400 bg-opacity-90 dark:bg-opacity-90 dark:bg-gray-400 z-30 tipsModal border-4 rounded-lg border-purple-200 dark:border-gray-200"
    >

    <div class="ubuntu-font font-semibold text-white dark:text-gray-700 text-left">
    <div class="relative flex flex-row justify-center">
        <h3 class="text-2xl text-gray-50 font-black select-none fredokaone-font font-bold some-shadow whitespace-pre mb-3">Tips/Move Notation</h3>
        <div class="w-8 h-8 absolute right-0 top-0 cursor-pointer text-white" id="closeTipsModal" @click="toggleModal">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
    </div>
        <ul>
            <li>You can go to <a class="text-lg text-gray-800" :href='"https://choss.gg/play/" + twitchChannel'>choss.gg/play/{{twitchChannel}}</a> to move the pieces live! </li>
			<li v-for="(tip, index) in tipsArray" :key="index" class="mb-4">{{tip}}</li>
        </ul>
    </div>
    </div>
</template>

<script>
export default {
	emits: ['toggle-modal'],
	props: {
		showing: String,
		twitchChannel: String,
		tipMessage: String
	},
	setup() {
		let tipsArray = [
				"Use '!move e4' or '!move e4-e5' in chat to make a move",
				"Example: '!move Ng6' will move your Knight to g6 (if the move is legal)",
				'King = K',
				'Queen = Q',
				'Bishop = B',
				'Knight = N',
				'Rook/Castle = R',
				"Pawns: you can either state '!move e4' or '!move e4-e5'",
				"You can also vote using '!move 1' to vote for a move already on the graph"
			]
		return {
			tipsArray
		}
	},
	methods: {
		toggleModal() {
			this.$emit('toggle-modal')
		},
	}
}
</script>

<style>
.tipsModal {
	padding: 1em;
	position: absolute;
	bottom: 1em;
	right: 1em;
}
</style>