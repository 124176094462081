<template>
    <div class="h-screen flex flex-col sm:flex-row bg-cpc-grey-700">
        <ResignModal :showResignModal="showResignModal" />
        <BugsModal :showing="bugsModal" :webCaptainId="web_captain_id" :twitchChannel="twitch_channel" @toggle-modal="toggleBugsModal"/>
        <!-- Add a placeholder for the Twitch embed -->
        <div class="w-full h-1/2 self-center sm:w-1/2 sm:h-full" id="twitch-embed"></div>
        <div class="h-full sm:h-full sm:w-1/2 flex flex-col relative" id="chessboard">
            <div class="flex flex-col w-full items-center select-none">
                <div class="flex flex-row items-center mb-2 pt-2">
                  <svg 
                      class="w-10 h-10 text-cpc-cyan-50"
                      width="58" 
                      height="50" 
                      viewBox="0 0 58 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.5714 0C12.7902 0 0 10.3906 0 23.2143C0 28.75 2.38839 33.817 6.36161 37.8013C4.96652 43.4263 0.301339 48.4375 0.245536 48.4933C0 48.75 -0.0669643 49.1295 0.078125 49.4643C0.223214 49.7991 0.535714 50 0.892857 50C8.29241 50 13.8393 46.4509 16.5848 44.2634C20.2344 45.6362 24.2857 46.4286 28.5714 46.4286C44.3527 46.4286 57.1429 36.0379 57.1429 23.2143C57.1429 10.3906 44.3527 0 28.5714 0ZM14.2857 26.7857C12.3103 26.7857 10.7143 25.1897 10.7143 23.2143C10.7143 21.2388 12.3103 19.6429 14.2857 19.6429C16.2612 19.6429 17.8571 21.2388 17.8571 23.2143C17.8571 25.1897 16.2612 26.7857 14.2857 26.7857ZM28.5714 26.7857C26.596 26.7857 25 25.1897 25 23.2143C25 21.2388 26.596 19.6429 28.5714 19.6429C30.5469 19.6429 32.1429 21.2388 32.1429 23.2143C32.1429 25.1897 30.5469 26.7857 28.5714 26.7857ZM42.8571 26.7857C40.8817 26.7857 39.2857 25.1897 39.2857 23.2143C39.2857 21.2388 40.8817 19.6429 42.8571 19.6429C44.8326 19.6429 46.4286 21.2388 46.4286 23.2143C46.4286 25.1897 44.8326 26.7857 42.8571 26.7857Z" fill="currentColor"/>
                    </svg>
                  <div class="ml-4 thambi-font-bold text-2xl text-white"><span class="text-cpc-cyan-50" id="status">Chat's Turn to Move!</span> <span class="invisible">(<span id="time">00</span>s)</span></div>
                </div>
                <div class="w-full px-16">
                    <div class="invisible w-full bg-cpc-grey-500 h-3 rounded-full">
                        <div class="invisible w-full bg-cpc-cyan-50 h-full rounded-full" id="new_timer"></div>
                    </div>
                </div>
            </div>
            <div class="text-white" v-if="loading === true" id="chessboard-loading">Chessboard Loading...</div>
            <Chessboard :key="game_state.fen_string+boardOrientation" v-if="loading === false" :pauseGame="pause_game" :boardOrientation="boardOrientation" :flipped="flipped" :gameState="game_state" :countdownTimer="chatTimer" @queue-move="queueMove"></Chessboard>
            <div class="flex flex-row w-full items-center justify-center content-center pt-2 pb-2 text-white">
                <ViewerBoardNav @toggle-bug-modal="toggleBugsModal" />
            </div>
        </div>
    </div>
</template>

<script>
import Chessboard from '@/components/Chessboard';
import { ref } from 'vue'
import { useRoute } from 'vue-router';
import API from '@/lib/API.js';
import ErrorLibrary from '@/lib/ErrorLibrary.js'
import { io } from 'socket.io-client';
import ViewerBoardNav from '@/components/ViewerBoardNav';
import BugsModal from '@/components/BugsModal';
import { useToast } from "vue-toastification";
import ResignModal from '@/components/ResignModal';

export default {
    components: {
        Chessboard,
        ViewerBoardNav,
        BugsModal,
        ResignModal
    },
    props: {
        playername: String,
    },
    setup(){
        let game_state = ref({});
        let loading = ref(true);
        let error = '';
        const route = useRoute();
        let streamername = route.params.streamername.toLowerCase();
        let web_captain_id = localStorage.getItem('web_captain_id');
        let twitch_channel = streamername;
        let socket_url;
        let transport;
        let bugsModal = ref('hidden');
        const toast = useToast();   
        let flipped = false;
        let boardOrientation = ref('white');
        let showResignModal = ref('hidden');
        let pause_game = ref(false);

        let chatTimer = 0;

        function findDiff(str1, str2){ 
            let diff= "";
            str2.split('').forEach(function(val, i){
                if (val != str1.charAt(i))
                diff += val ;         
            });
            return diff;
        }

        if (window.location.host.includes('localhost')){
            // local paths
            socket_url = "http://localhost:9502";
            transport = ["websocket"];
        }else if (window.location.host.includes('dev1')){
            //dev path
            socket_url = "https://socket.dev1.chatplayschess.com";
            transport = ["polling"];
        }else { 
            //prod paths
            socket_url = "https://socket.chatplayschess.com";
            transport = ["polling"];
        }
        return{ 
            game_state,
            loading,
            error,
            streamername,
            socket: {},
            socket_url,
            transport,
            findDiff,
            bugsModal,
            twitch_channel,
            chatTimer,
            toast,
            flipped,
            boardOrientation,
            web_captain_id,
            showResignModal,
            pause_game,
        }
    },
    mounted(){
       this.loadEmbed()
       let app = this;
       let streamername = this.$route.params.streamername.toLowerCase();
       API.getGameState(streamername.toLowerCase())
       .then((result) => {
           if(result.success == true && result.payload.length > 0){
                this.game_state = result.payload[0];
                this.flipped = result.payload[0].flipped;
                this.chatTimer = result.payload[0].chat_timer
                this.loading = false;
                console.log('stream embed.vue', this.game_state.value);
           }else{
               console.log('Error while loading:', result.error);
               if(result.error == 'NO_SESSION_FOUND'){
                   document.getElementById('chessboard-loading').innerHTML = "Chessboard Error: No valid game session found for user: " + streamername + "<br>This could be a server error. Or the streamer hasn't started a game yet! Sit tight.<br>Use the bug report button below to let us know if this board doesn't work while your streamer is live! <br>If your streamer is live and playing ChatPlaysChess, try refreshing.";
               }else{
                   document.getElementById('chessboard-loading').innerHTML = "Chessboard Error: No valid game session found for user: " + streamername + "<br>This could be a server error. Or the streamer hasn't started a game yet! Sit tight.<br>Use the bug report button below to let us know if this board doesn't work while your streamer is live! <br>If your streamer is live and playing ChatPlaysChess, try refreshing.";
               }
               this.error = result.error;
           }
       })
       .catch(error => { 
           document.getElementById('chessboard-loading').innerHTML = 'Chessboard failed to load. Please try refreshing';
           console.log(error);
        });
        
        this.socket = io.connect(this.socket_url, { transports: this.transport });
         //socket.io functions
        this.socket.on('connect', async () => {
            console.log('connected');
            this.socket.emit('join_room', streamername, app.playername);
            this.socket.on('update_board', (countdownTimer) => {
                console.log('update_board event received; timer:', countdownTimer);
                app.chatTimer = countdownTimer;
                app.updateGameState();
            })
            this.socket.on('update_board_bugged', (countdownTimer) => {
                console.log('update_board_bugged event received resetting game_state; timer:', countdownTimer);
                app.chatTimer = countdownTimer;
                app.toast.error('Voting glitched. Resetting votes!')
                this.game_state.fen_string = null;
                app.updateGameState();
            })
            this.socket.on('update_board_flipped', (countdownTimer, flipped_val) => {
                console.log('update_board_flipped event received resetting game_state; timer:', countdownTimer, flipped_val);
                app.chatTimer = countdownTimer;
                this.game_state.fen_string = null;
                this.flipped = flipped_val;
                app.updateGameState(flipped_val);
            })
            this.socket.on('reset_board', () => {
                app.resetBoardState();
            })
            this.socket.on('custom_event', (event_name, event_val) => {
                if(event_name == 'pause_game'){
                    console.log('pause_game_event received:', event_val);
                    this.pause_game = event_val;
                }
            })
        })
        //socket.io functions

        
        document.getElementById('VoteResign').onclick = () => {
            console.log('Voted Resign', app.web_captain_id);
            this.socket.emit('queue_move', 'resign', streamername, app.web_captain_id);
            this.showResignModal = 'hidden'
        }
        document.getElementById('CancelResign').onclick = () => {
            this.showResignModal = 'hidden';
        }
        //Nav button functions

        document.getElementById('ShowResignModal').onclick = () => {
            if(this.showResignModal) {
                this.showResignModal = 'block'
            } else {
                this.showResignModal = 'hidden'
            }
        }

        document.getElementById('FlipBoard').onclick = () => {
            console.log('Flipping Board', app.boardOrientation);
            if(app.boardOrientation == 'white'){
                app.boardOrientation = 'black';
            }else{
                app.boardOrientation = 'white';
            }
        }
        //Nav button functions
    }, 
    methods: {
        loadEmbed() {
            this.$loadScript("https://embed.twitch.tv/embed/v1.js").then(() => {
                new window.Twitch.Embed("twitch-embed", {
                    width: "100%",
                    height: "100%",
                    channel: this.$route.params.streamername,
                    //parent: ['18.221.73.62:8081']
                });
            }).catch((err) => {
                console.log(err);
            })            
        },
        updateGameState(flipped_val = null){
            //this.loading = true;
            console.log('updating game state', flipped_val);
            API.getGameState(this.streamername.toLowerCase())
            .then((result) => {
                if(result.success == true){
                        if(this.game_state.fen_string !== result.payload[0].fen_string){
                            this.flipped = result.payload[0].flipped;
                            if(flipped_val){
                                this.game_state.flipped = flipped_val;
                                this.flipped = flipped_val;
                            }
                            this.game_state = result.payload[0];
                        }
                }else{
                    this.error = result.error;
                }
            })
            .catch(error => { 
                document.getElementById('chessboard-loading').innerHTML = 'Chessboard failed to load. Please try refreshing';
                console.log(error);
            });
        },
        resetBoardState(){
            //this.loading = true;
            console.log('resetting game state');
            API.getGameState(this.streamername.toLowerCase())
            .then((result) => {
                if(result.success == true){
                    this.game_state = result.payload[0];
                    this.$emit('update_status');
                }else{
                    this.error = result.error;
                }
            })
            .catch(error => { 
                document.getElementById('chessboard-loading').innerHTML = 'Chessboard failed to load. Please try refreshing';
                console.log(error);
            });
        },
        queueMove(move, streamername, web_captain_id){
            this.socket.emit('queue_move', move, streamername, web_captain_id);
        },
        toggleBugsModal(){
            if(this.bugsModal == 'hidden'){
                this.bugsModal = 'block';
            }else{
                this.bugsModal = 'hidden';
            }
        },
    }
}
</script>

<style scoped>
#new_timer{
    display: flex;
    /*transition-property: width;*/
    transition: width 1s;
    transition-timing-function: linear;
    /*transition-timing-function: linear;*/
}

</style>