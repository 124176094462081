<template>
  <div class="">
  </div>
</template>

<script>
import Router from '@/router';
export default {
    setup(){  
      let params = Router.currentRoute.value.params;
      let default_picture = false;
      default_picture = params.default_picture;

      let user_name = params.user_name;
      let access_token = params.access_token;
      let user_id = params.user_id;
      let refresh_token = params.refresh_token;
      let profile_picture = params.profile_picture;
      let mode = 'twitch';
      
      try{
        localStorage.setItem('twitch_channel', user_name);
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('user_id', user_id);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('web_captain_id', user_name);
        
        if(default_picture == "false" || default_picture == false){
          localStorage.setItem('profile_picture', 'https://static-cdn.jtvnw.net/jtv_user_pictures/' + profile_picture);
        }else{
          localStorage.setItem('profile_picture', 'https://static-cdn.jtvnw.net/user-default-pictures-uv/' + profile_picture);
        }
        
      }catch(err){
        console.log(err);
      }
      
     //console.log(user_name, access_token, user_id, refresh_token, mode);
     try{
        let path = sessionStorage.getItem('path');
        let streamerpath = sessionStorage.getItem('streamerpath')
        if(path == 'viewerboard' && streamerpath){
          Router.push({name: 'Live Chess Board', params: {streamername: streamerpath}})
        }else{
          Router.push({name: 'Choose Game', params: {twitch_channel: user_name, mode: mode}})
        }
     }catch(e){
       console.log(e);
       Router.push({name: 'Choose Game', params: {twitch_channel: user_name, mode: mode}})
     }
    }
}
</script>

<style>

</style>