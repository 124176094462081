import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./main.css";
import "./assets/fonts/stylesheet.css"
import "./assets/css/chessboard-arrows.css"
import { createI18n } from "vue-i18n";
import LoadScript from "vue-plugin-load-script";
import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";

function loadLocaleMessages(){
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
   return messages;
}

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    globalInjection: true,
    messages: loadLocaleMessages()
});

const toastOptions = {
    maxToasts: 3,
    timeout: 6000,
    pauseOnFocusLoss: false,
}

createApp(App).use(LoadScript).use(i18n).use(router).use(Toast, toastOptions).mount('#app');
