<template>
    <div class="flex flex-col justify-center">
        <div class="relative shadow-xl mb-8">
            <svg class="relative" width="450" height="500" viewBox="0 0 450 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="450" height="500" rx="12" fill="#646372"/>
                <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="450" height="500">
                <rect width="450" height="500" rx="12" fill="#646372"/>
                </mask>
                <g mask="url(#mask0)">
                <path d="M-17.5 517.5L464.5 -8L454 517.5H-17.5Z" fill="#888798"/>
                </g>
            </svg>
            <div class="absolute baloo-font text-5xl text-white top-0 w-full text-center mt-7 select-none">
                <div class="w-full absolute purple-stroke text-center">{{cardTitle}}</div>
                <div class="w-full absolute text-center">{{cardTitle}}</div>
            </div>
            <div class="absolute top-24 w-full select-none">
                <div class="absolute left-2">
                    <slot name="left-icon"></slot>
                </div>
                <div class="absolute right-0">
                    <slot name="right-icon"></slot>
                </div>
                <!--<span class="w-full text-center justify-center text-white baloo-font text-2xl">[IMAGE GOES HERE]</span>-->
            </div>
        </div>
        <div v-if="chooseSide" class="select-none hidden">
            <span class="baloo-font text-2xl text-white purple-stroke-md absolute">Streamer starts as White</span>
            <span class="baloo-font text-2xl text-white absolute">Streamer starts as White</span>
        </div>
        <GamePlayButton @click="goTo(buttonRoute)" v-if="buttonText !== 'Coming Soon!'" :text="buttonText" color="bg-cpc-cyan-50 text-gray-800 hover:text-gray-800 w-6/12 border-yellow-400 hover:border-yellow-500 hover:bg-cpc-cyan-100 button-shadow-cyan active:bg-yellow-600 z-20 justify-center text-center"></GamePlayButton>
        <GamePlayButton v-else :text="buttonText" color="cursor-not-allowed bg-cpc-bg-grey-600 text-white text-opacity-30 hover:text-opacity-30 w-6/12 border-yellow-400 z-20 justify-center text-center"></GamePlayButton>
    </div>
</template>

<script>
import GamePlayButton from "@/components/GamePlayButton";
import Router from "@/router";

export default {
    components:{ 
        GamePlayButton,
    },
    props: {
        cardTitle: String,
        buttonText: String,
        chooseSide: Boolean,
        buttonRoute: String,
    },
    setup(){ 
        function goTo(route){
            Router.push({name: route});
        }

        return {
            goTo,
        }
    }
}
</script>

<style scoped>
    .purple-stroke{ 
        -webkit-text-stroke: 10px #1F1D2B;
    }
    .purple-stroke-md{ 
        -webkit-text-stroke: 5px #1F1D2B;
    }

    /*Button Shadows */
    .button-shadow{
        box-shadow: 0 -5px 0 0 #cbafff, 0 6px 0 0 #7f43ec, 0 19px 0 0 rgb(0 0 0 / 11%);
    }

    .button-shadow-yellow{
        box-shadow: 0 -5px 0 0 #f6e398, 0 6px 0 0 #f39247, 0 19px 0 0 rgb(0 0 0 / 11%);
    }
    .button-shadow-cyan{
        box-shadow: 0 -5px 0 0 #b8ffdc, 0 6px 0 0 #3c8a64, 0 19px 0 0 rgb(0 0 0 / 11%);
    }
    .button-shadow:hover{
        box-shadow: 0 -5px 0 0 #d5bffd, 0 6px 0 0 #8851ec, 0 19px 0 0 rgb(0 0 0 / 11%);
    }
    .button-shadow-yellow:hover{
        box-shadow: 0 -5px 0 0 #f6e398, 0 6px 0 0 #f39247, 0 19px 0 0 rgb(0 0 0 / 11%);
    }
    .button-shadow-cyan:hover{
        box-shadow: 0 -5px 0 0 #b8ffdc, 0 6px 0 0 #3c8a64, 0 19px 0 0 rgb(0 0 0 / 11%);
    }
    .button-shadow:active{
        transform: translateY(5px);
        box-shadow: 0 -5px 0 0 #d5bffd, 0 6px 0 0 #8851ec, 0 14px 0 0 rgb(0 0 0 / 11%);
    }
    .button-shadow-yellow:active{
        transform: translateY(5px);
        box-shadow: 0 -5px 0 0 #ffb763, 0 6px 0 0 #c6590f, 0 14px 0 0 rgb(0 0 0 / 11%);
    }
    .button-shadow-cyan:active{
        transform: translateY(5px);
        box-shadow: 0 -5px 0 0 #8ff3c1, 0 6px 0 0 #169658, 0 19px 0 0 rgb(0 0 0 / 11%);
    }

    .some-shadow{
        text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
    }
</style>