<template>
  <div class="h-screen w-full bg-cpc-grey-400 flex flex-col p-10 items-center justify-center select-none">
    <div class="container thambi-font-bold text-6xl text-white text-center">{{ $t("sorry", {}, { locale: chosen_lang ? chosen_lang : default_lang}) }}</div>
    <br>
    <div class="container text-2xl ubuntu-font text-white text-center">
        {{ $t("appreciation", {}, { locale: chosen_lang ? chosen_lang : default_lang}) }}
        <br>
        {{ $t("betaInvite", {}, { locale: chosen_lang ? chosen_lang : default_lang}) }}
    </div>
    <br>
    <!--
    <div class="pb-5 w-1/5">
        <input
            type="text"
            class="flex sm:p-6 placeholder-gray-400 w-full border rounded-xl focus:border-indigo-300 pl-4 h-10 transition ease-in duration-200 focus:outline-none focus:shadow-outline focus:shadow-lg active:shadow-lg"
            :placeholder="$t('placeholderEmail', {}, { locale: chosen_lang ? chosen_lang : default_lang })"
            id="email"
            autocomplete="off"
        />
    </div>
    -->
    <a target="_blank" href="https://stream-captain.typeform.com/to/qnD5uZXb">
    <button :disabled="sending" :class="{'bg-gray-600 hover:bg-gray-600 cursor-not-allowed hover:cursor-not-allowed': sending, ' bg-cpc-cyan-100 hover:bg-cpc-cyan-50 ': !sending }" class="flex items-center px-8 py-4 focus:outline-none justify-center rounded-xl  shadow-xl text-cpc-grey-700 ">
        <svg class="w-5 h-5 transform rotate-90 -mr-px"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
        </svg>
        <span class="pl-2 thambi-font-bold text-xl">{{ $t("signUp", {}, { locale: chosen_lang ? chosen_lang : default_lang}) }}</span>
    </button>
    </a>
      
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import API from '@/lib/API.js';
import { ref } from 'vue';

export default {
    setup(){
        let sending = ref(false);
        //grab localization from window? then pass as var.
        let default_lang = ref(getDefaultLocale());
        let chosen_lang = ref(false);
        
        function getDefaultLocale(){
            let default_lang = navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en';
            return default_lang;
        }

        return {
            sending,
            default_lang,
            chosen_lang,
        }
    },
    methods: {
        async sendMail() {
            this.sending = true;
            let error_swal = ['Error', `Something went wrong. Please try again.`, 'error'];
            let email = document.getElementById('email').value;
            if(email != ''){
                let from_string = `<${email}>`;
                let data = {
                    from: `postmaster@mg.socialites.app`,
                    to: 'chuckokere@socialites.app',
                    subject: 'CHESS ON STREAM BETA SIGNUP',
                    text: `${from_string}\n\nThis person is interested in the beta: ${email}`,
                };
                const result = await API.sendEmail(data);
                if(result instanceof Error){
                    this.sending = false;
                    Swal.fire(...error_swal);
                }
                try{
                    Swal.fire(...result.swal);
                }catch(e){
                    this.sending = false;
                    console.log(e);
                    Swal.fire(...error_swal);
                }

                if(result.success){
                    this.sending = false;
                    document.getElementById('email').value = '';
                }
                
            }else{
                this.sending = false;
                Swal.fire('Error', `Please fill out all fields to proceed.`, 'error');
            }
        }
    }
}
</script>

<style>

</style>