<template>
  <StreamEmbed :playername="web_captain_id"></StreamEmbed>
</template>

<script>
import StreamEmbed from '@/components/StreamEmbed';
import API from '@/lib/API.js';
import Router from '@/router';

export default {
    components:{ 
        StreamEmbed,
    },
    setup(){
      let web_captain_id;
      let params = Router.currentRoute.value.params;

      if(localStorage.getItem('web_captain_id') !== null && web_captain_id === undefined){
        web_captain_id = localStorage.getItem('web_captain_id').toLowerCase();
      }else{
        sessionStorage.setItem('path', 'viewerboard');
        sessionStorage.setItem('streamerpath', params.streamername);
        
        API.twitchAuthorize();
      } 
      
      return {
        web_captain_id
      }
    },
    mounted(){

    },
    methods: {

    } 
}
</script>

<style>

</style>