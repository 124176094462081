<template>
    <div class="flex flex-col absolute right-10 w-min items-center h-full justify-center" id="nav">
        <button @click="openSettingsModal" class="flex items-center focus:outline-none justify-center text-gray-400 hover:text-white dark:hover:text-white dark:text-gray-400 h-10 w-10 ease-in rounded mb-4">
            <svg 
                class="w-10 h-10"
                width="48" 
                height="48" 
                viewBox="0 0 48 48" 
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M46.5605 29.2599L42.1485 25.8099C42.2853 24.6058 42.2853 23.39 42.1485 22.1859L46.5645 18.734C47.392 18.0675 47.6132 16.8988 47.0865 15.9759L42.4965 8.04201C41.9679 7.11333 40.8419 6.70776 39.8426 7.08604L34.6426 9.17807C33.6654 8.46913 32.6187 7.86135 31.5185 7.36411L30.7205 1.8401C30.5766 0.7796 29.6668 -0.00864709 28.5965 7.16225e-05H19.3966C18.3353 -0.00630335 17.4327 0.773131 17.2846 1.82406L16.4847 7.36401C15.3879 7.86604 14.3429 8.47429 13.3647 9.18004L8.1506 7.08398C7.16154 6.69408 6.03664 7.09476 5.51661 8.02195L0.922596 15.9699C0.381754 16.8943 0.60441 18.0758 1.44459 18.74L5.85655 22.19C5.71845 23.394 5.71845 24.6099 5.85655 25.8139L1.44056 29.2639C0.611628 29.9295 0.389442 31.0988 0.916596 32.0219L5.50461 39.9579C6.03261 40.8873 7.1592 41.2931 8.15857 40.9139L13.3586 38.8219C14.3366 39.5312 15.3839 40.1396 16.4846 40.6379L17.2845 46.1579C17.4253 47.2156 18.3296 48.0042 19.3965 47.9999H28.5965C29.6598 48.0084 30.5652 47.2287 30.7144 46.1759L31.5144 40.6359C32.6111 40.1337 33.656 39.5255 34.6344 38.8199L39.8544 40.9179C40.8434 41.3069 41.9678 40.9065 42.4884 39.98L47.0964 32C47.6123 31.0784 47.3855 29.9191 46.5605 29.2599ZM23.9965 33.9999C18.4737 33.9999 13.9965 29.5228 13.9965 23.9999C13.9965 18.477 18.4737 13.9999 23.9965 13.9999C29.5194 13.9999 33.9965 18.477 33.9965 23.9999C33.9899 29.5201 29.5166 33.9933 23.9965 33.9999Z" fill="currentColor"/>
            </svg>
        </button>
        <button @click="openBugsModal" class="flex items-center focus:outline-none justify-center text-gray-400 hover:text-white dark:hover:text-white dark:text-gray-400 h-10 w-10 ease-in rounded mb-4">
            <svg 
                class="w-10 h-10"
                width="49" 
                height="49" 
                viewBox="0 0 49 49" 
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M48.9988 27.6486C48.9531 29.3167 47.5425 30.625 45.8738 30.625H40.5781V32.1562C40.5781 34.2487 40.1109 36.2316 39.2765 38.008L45.0405 43.772C46.2364 44.968 46.2364 46.907 45.0405 48.103C43.8444 49.299 41.9054 49.2989 40.7094 48.103L35.471 42.8646C33.1023 44.7857 30.0843 45.9375 26.7968 45.9375V22.5859C26.7968 21.9517 26.2826 21.4375 25.6484 21.4375H23.3515C22.7173 21.4375 22.2031 21.9517 22.2031 22.5859V45.9375C18.9156 45.9375 15.8976 44.7857 13.5289 42.8646L8.29052 48.103C7.09442 49.299 5.15538 49.2989 3.95937 48.103C2.76347 46.907 2.76347 44.968 3.95937 43.772L9.72338 38.008C8.88904 36.2316 8.42182 34.2487 8.42182 32.1562V30.625H3.12618C1.4575 30.625 0.0469324 29.3167 0.00118633 27.6486C-0.0463781 25.9179 1.34247 24.5 3.06244 24.5H8.42182V18.8779L3.95937 14.4155C2.76347 13.2195 2.76347 11.2804 3.95937 10.0844C5.15547 8.88842 7.09442 8.88842 8.29052 10.0844L13.5185 15.3125H35.4813L40.7093 10.0845C41.9054 8.88852 43.8443 8.88852 45.0404 10.0845C46.2363 11.2805 46.2363 13.2196 45.0404 14.4156L40.5781 18.8779V24.5H45.9375C47.6574 24.5 49.0463 25.9179 48.9988 27.6486ZM24.5957 0C18.6758 0 13.8769 4.79893 13.8769 10.7187H35.3144C35.3144 4.79893 30.5155 0 24.5957 0Z" fill="currentColor"/>
            </svg>
        </button>

        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20 mb-5" id="PauseGame">
           <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-36">Pause</span>
            <svg
                class="w-6 h-6 text-gray-800" 
                width="24" 
                height="32" 
                viewBox="0 0 24 32"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="8" height="32" rx="4" fill="currentColor"/>
                <rect x="16" width="8" height="32" rx="4" fill="currentColor"/>
            </svg>
        </GameButton>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20 mb-5" id="RefundMove">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-48">Undo Move</span>
            <svg 
                class="w-6 h-6 text-gray-800"
                width="30" 
                height="30" 
                viewBox="0 0 30 30" 
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6305 13.3532H0.713815C0.319611 13.3532 0 13.0333 0 12.6389V0.714286C0 0.319821 0.319611 0 0.713815 0H3.56907C3.96328 0 4.28289 0.319821 4.28289 0.714286V5.36381C7.00567 2.33804 10.9606 0.444643 15.3574 0.476607C23.5012 0.535774 30.0173 7.12083 30 15.2702C29.9827 23.4082 23.3846 30 15.2479 30C11.4355 30 7.96123 28.5528 5.34308 26.1777C5.03977 25.9026 5.02579 25.4304 5.3153 25.1408L7.33581 23.1189C7.60195 22.8526 8.02952 22.8382 8.31142 23.0877C10.1576 24.7224 12.5857 25.7143 15.2479 25.7143C21.0338 25.7143 25.7171 21.0288 25.7171 15.2381C25.7171 9.44839 21.0347 4.7619 15.2479 4.7619C11.7682 4.7619 8.6879 6.4569 6.78475 9.06744H12.6305C13.0247 9.06744 13.3443 9.38726 13.3443 9.78173V12.6389C13.3443 13.0333 13.0247 13.3532 12.6305 13.3532Z" fill="currentColor"/>
            </svg>
        </GameButton>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20 mb-5" id="ToggleChatVoting">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-72">Toggle In-Chat Voting</span>
            <svg 
                class="w-6 h-6 text-gray-800"
                width="36" 
                height="36" 
                viewBox="0 0 36 36" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
            <path d="M32.1429 0H3.85714C1.72768 0 0 1.72768 0 3.85714V32.1429C0 34.2723 1.72768 36 3.85714 36H32.1429C34.2723 36 36 34.2723 36 32.1429V3.85714C36 1.72768 34.2723 0 32.1429 0ZM12.8571 27C12.8571 27.7104 12.2818 28.2857 11.5714 28.2857H9C8.28964 28.2857 7.71429 27.7104 7.71429 27V16.7143C7.71429 16.0039 8.28964 15.4286 9 15.4286H11.5714C12.2818 15.4286 12.8571 16.0039 12.8571 16.7143V27ZM20.5714 27C20.5714 27.7104 19.9961 28.2857 19.2857 28.2857H16.7143C16.0039 28.2857 15.4286 27.7104 15.4286 27V9C15.4286 8.28964 16.0039 7.71429 16.7143 7.71429H19.2857C19.9961 7.71429 20.5714 8.28964 20.5714 9V27ZM28.2857 27C28.2857 27.7104 27.7104 28.2857 27 28.2857H24.4286C23.7182 28.2857 23.1429 27.7104 23.1429 27V21.8571C23.1429 21.1468 23.7182 20.5714 24.4286 20.5714H27C27.7104 20.5714 28.2857 21.1468 28.2857 21.8571V27Z" fill="currentColor"/>
            </svg>

        </GameButton>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20 mb-5" id="ShowRestartGameModal">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-40">Restart</span>
            <svg 
                class="w-6 h-6 text-gray-800"
                width="34" 
                height="34" 
                viewBox="0 0 34 34" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M26.871 3.6496C31.1895 6.68531 34 11.6504 34 17.2698C34 26.4984 26.4048 33.9797 17.0343 34C7.67743 34.0202 0.0137222 26.5119 1.25112e-05 17.2968C-0.00684232 11.6774 2.80364 6.6988 7.11533 3.65634C7.91735 3.09643 9.03469 3.33254 9.51453 4.17579L10.5976 6.07142C11.002 6.77975 10.8101 7.67698 10.1452 8.16269C7.30041 10.2405 5.48388 13.5325 5.48388 17.2631C5.47703 23.4897 10.5907 28.6031 17 28.6031C23.279 28.6031 28.5573 23.5976 28.5161 17.1956C28.4956 13.7012 26.823 10.3282 23.848 8.15594C23.1831 7.67023 22.998 6.77301 23.4024 6.07142L24.4855 4.17579C24.9653 3.33928 26.0758 3.08968 26.871 3.6496ZM19.7419 17.8095V1.61905C19.7419 0.721825 19.0085 0 18.0968 0H15.9032C14.9915 0 14.2581 0.721825 14.2581 1.61905V17.8095C14.2581 18.7067 14.9915 19.4285 15.9032 19.4285H18.0968C19.0085 19.4285 19.7419 18.7067 19.7419 17.8095Z" fill="currentColor"/>
            </svg>
        </GameButton>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20 mb-5" id="ShowFlipModal">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-56">Changes Sides</span>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                class="w-6 h-6 text-gray-800" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
            </svg>
        </GameButton>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20 mb-5" id="ShowResignModal">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-36">Resign</span>
            <svg xmlns="http://www.w3.org/2000/svg" 
                width="16" 
                height="16" 
                fill="currentColor" 
                class="bi bi-flag-fill w-6 h-6 text-gray-800" viewBox="0 0 16 16">
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
            </svg>
        </GameButton>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20 mb-5" id="GameModes">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-72">Change Game Mode</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
                class="w-6 h-6 text-gray-800 bi bi-joystick" 
                viewBox="0 0 16 16">
                <path d="M10 2a2 2 0 0 1-1.5 1.937v5.087c.863.083 1.5.377 1.5.726 0 .414-.895.75-2 .75s-2-.336-2-.75c0-.35.637-.643 1.5-.726V3.937A2 2 0 1 1 10 2z"/>
                <path d="M0 9.665v1.717a1 1 0 0 0 .553.894l6.553 3.277a2 2 0 0 0 1.788 0l6.553-3.277a1 1 0 0 0 .553-.894V9.665c0-.1-.06-.19-.152-.23L9.5 6.715v.993l5.227 2.178a.125.125 0 0 1 .001.23l-5.94 2.546a2 2 0 0 1-1.576 0l-5.94-2.546a.125.125 0 0 1 .001-.23L6.5 7.708l-.013-.988L.152 9.435a.25.25 0 0 0-.152.23z"/>
            </svg>
        </GameButton>
    </div>
</template>

<script>

import GameButton from "@/components/GameButton"
export default {
    components: {
        GameButton,
    },
    methods: {
        openSettingsModal(){
            this.$emit('toggle-modal');
        },
        openBugsModal(){
            this.$emit('toggle-bug-modal');
        },
    }

}
</script>

<style>


#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.tooltip {
  @apply invisible;
  @apply absolute;
}
.has-tooltip:hover .tooltip {
  @apply visible;
  @apply z-50;
}
</style>