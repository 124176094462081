<template>
    <div class=" flex flex-col float-left items-center justify-center">
        <div class="chess-title text-8xl select-none flex flex-col font-bold some-shadow">
            <span class="text-4xl  text-center text-white  thambi-font-bold">CHAT PLAYS</span>
            <span class="text-7xl  text-center text-cpc-cyan-50 thambi-font-bold -mt-2">CHESS</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>