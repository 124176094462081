
// track - browser js edition

const AWS = require('aws-sdk');
const mixpanel = require('mixpanel-browser');

let environment;
if(window.location.host.includes('dev1') || window.location.host.includes('localhost')){
    environment = 'dev';
}else{
    environment = 'prod'
}
const baseProps = {
    'app': 'choss',
    'env': environment,
};

let sequenceNumber = 0;

const creds = {
    dev: {
        aws: {
            key: 'AKIA6LGU5DMEIIJ4LIGJ',
            secret: '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',
        },
        mixpanel: {
            key: '421d58f595c5c49614f9cf28b5cf3395',
        }
    },
    prod: {
        aws: {
            key: 'AKIA6LGU5DMEIIJ4LIGJ',
            secret: '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',
        },
        mixpanel: {
            key: '421d58f595c5c49614f9cf28b5cf3395',
        }
    }
};


/// UTIL


function pad2(number) {
    let str = '' + number;
    while (str.length < 2) {
        str = '0' + str;
    }

    return str;
}

function timestampString(){
    const dt = new Date().toLocaleString("en-US", {timeZone: "UTC"});
    const utc_date = new Date(dt);
    return utc_date.getFullYear()
        + '-' + pad2(utc_date.getMonth()+1)
        + '-' + pad2(utc_date.getDate())
        + ' ' + pad2(utc_date.getHours())
        + ':' + pad2(utc_date.getMinutes())
        + ':' + pad2(utc_date.getSeconds());
}

/// AWS


AWS.config.update({
    region: 'us-east-2',
    accessKeyId: creds[baseProps.env].aws.key,
    secretAccessKey: creds[baseProps.env].aws.secret,
});

const firehose = new AWS.Firehose();

const streamName = 'cdas-'+baseProps.app+'-'+baseProps.env;

/// FIREHOSE

const firehoseEvent = (event, props) => {
    Object.assign(props, {
        'event': event,
        'app_ts': timestampString(),
        'seq': ++sequenceNumber,
    });
    Object.assign(props, baseProps);

    console.log('track event:', props);
    const params = {
        DeliveryStreamName: streamName,
        Record: {
            Data: JSON.stringify(props),
        },
    };
    firehose.putRecord(params, function(err/*, data*/) {
        if (err){
            console.log('track error', err, err.stack);
        }
    });
};


/// MIXPANEL

        
mixpanel.init(creds[baseProps.env].mixpanel.key);

console.log('track setup on mixpanel', environment);

const mixpanelEvent = (event, props) => {
    mixpanel.track(event, props);
}


/// EXPORTS

exports.event = function(event, props){
    firehoseEvent(event, props);
    mixpanelEvent(event, props);
};

exports.identify = function(event, props){
    mixpanel.identify(event, props);
};






