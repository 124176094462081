<template>
  <div :class="showing" class="w-full min-h-full bg-scroll bg-black bg-opacity-50 absolute z-30">
      <div class="relative w-full h-full m-auto container flex items-center justify-center p-36">
        <div class="bg-cpc-grey-400 dark:bg-cpc-grey-400 border-4 border-cpc-grey-400 dark:border-cpc-grey-400 rounded-3xl w-1/2 p-16 relative">
            <div class="w-8 h-8 absolute right-8 top-8 cursor-pointer text-white dark:text-white" id="closeSettings" @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div class="ubuntu-font text-4xl font-semibold text-cpc-cyan-100 dark:text-cpc-cyan-100 text-center select-none">Feedback/Bug Report</div>
            <div class="mt-8 ">
                <div class="h-48 pb-5">
                    <textarea
                        class="p-2 sm:p-5 flex form-textarea box-border w-full h-full sm:h-full placeholder-gray-400 dark:placeholder-gray-400  bg-cpc-grey-400 dark:bg-cpc-grey-400 dark:border-cpc-cyan-100 dark:focus:border-cpc-cyan-50 dark:text-white  text-white border-2 rounded-xl focus:outline-none focus:border-cpc-cyan-100 border-cpc-cyan-50 px-4 transition ease-in duration-200 focus:shadow-outline focus:shadow-lg active:shadow-lg"
                        :placeholder="$t('describeBug', {}, { locale: chosen_lang ? chosen_lang : default_lang })"
                        id="body"
                        autocomplete="off"
                    ></textarea>
                </div>
                <button @click="sendBugReport" :disabled="sending" :class="{'bg-gray-600 hover:bg-gray-600 cursor-not-allowed hover:cursor-not-allowed': sending, ' bg-cpc-cyan-50 hover:bg-cpc-cyan-100 dark:bg-cpc-cyan-50 dark:hover:bg-cpc-cyan-100': !sending }" class="flex items-center px-8 py-4 focus:outline-none justify-center rounded-xl  shadow-xl text-cpc-grey-200 dark:text-cpc-grey-200">
                    <svg class="w-5 h-5 transform rotate-90 -mr-px"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                    </svg>
                    <span class="pl-2 fredokaone-font">{{ $t("submit", {}, { locale: chosen_lang ? chosen_lang : default_lang}) }}</span>
                </button>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import API from '@/lib/API.js';
import { ref } from 'vue';

export default {
    emits: ['toggle-modal'],
    props: {
        showing: String,
        twitchChannel: String,
        webCaptainId: String,
    },
    setup(){
        let sending = ref(false);
        //grab localization from window? then pass as var.
        let default_lang = ref(getDefaultLocale());
        let chosen_lang = ref(false);
        
        function getDefaultLocale(){
            let default_lang = navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en';
            return default_lang;
        }

        return {
            sending,
            default_lang,
            chosen_lang,
        }
    },
    mounted(){

    },
    methods: {
       toggleModal(){
           this.$emit('toggle-modal');
       },
       async sendMail() {
            this.sending = true;
            let error_swal = ['Error', `Something went wrong. Please try again.`, 'error'];
            if(this.twitchChannel){
                let from_string = `<${this.twitchChannel}>`;
                let body = document.getElementById('body').value;
                let data = {
                    from: `postmaster@mg.socialites.app`,
                    to: 'chatplayschess@captain.tv',
                    subject: 'CHESS ON STREAM BUG REPORT',
                    text: `${from_string}\n\nBug Report: ${body}`,
                };
                const result = await API.sendEmail(data);
                if(result instanceof Error){
                    this.sending = false;
                    Swal.fire(...error_swal);
                }
                try{
                    Swal.fire(...result.swal);
                }catch(e){
                    this.sending = false;
                    console.log(e);
                    Swal.fire(...error_swal);
                }

                if(result.success){
                    this.sending = false;
                    document.getElementById('body').value = '';
                }
                
            }else{
                this.sending = false;
                Swal.fire('Error', `Please fill out all fields to proceed.`, 'error');
            }
        },
        async sendBugReport() {
            this.sending = true;
            let error_swal = ['Error', `Something went wrong. Please try again.`, 'error'];
            if(this.twitchChannel){
                let from_string = this.webCaptainId ? `${this.webCaptainId} reporting from ${this.twitchChannel}'s board` : '';
                let body = document.getElementById('body').value;
                let data = {
                    from: `<${this.twitchChannel}>`,
                    subject: 'Chess On Stream Bug Report',
                    text: `[${this.twitchChannel}](https://twitch.tv/${this.twitchChannel})\n\nBug Report: ${body} \n\n${from_string}`,
                }
                const result = await API.sendBug(data);
                if(result instanceof Error){
                    this.sending = false;
                    Swal.fire(...error_swal);
                }
                try{
                    Swal.fire(...result.swal);
                }catch(e){
                    this.sending = false;
                    console.log(e);
                    Swal.fire(...error_swal);
                }

                if(result.success){
                    this.sending = false;
                    document.getElementById('body').value = '';
                }
            }else{
                this.sending = false;
                Swal.fire('Error', `Please fill out all fields to proceed.`, 'error');
            }
        }
    },
}
</script>

<style>

</style>