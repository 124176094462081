<template>
  <div :class="showing" class="w-full h-screen bg-black bg-opacity-50 absolute z-30">
      <div class="relative w-full h-full m-auto container flex items-center justify-center p-36">
        <div class="bg-cpc-grey-200 dark:bg-cpc-grey-200 border-4 border-cpc-grey-200 dark:border-cpc-grey-200 rounded-3xl w-1/2 p-16 relative">
            <div class="w-8 h-8 absolute right-8 top-8 cursor-pointer text-gray-200 dark:text-gray-200" id="closeWinModal" @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div class="ubuntu-font text-4xl font-semibold text-cpc-cyan-50 dark:text-cpc-cyan-50 text-center select-none">Game Over!</div>
            <div class="mt-8 flex flex-col items-center px-10 ">
                <lottie-player v-if="icon == '/imgs/win_trophy.json'" class="w-full h-full" src="/imgs/win_trophy.json"  background="transparent"  speed="1.4" loop  autoplay></lottie-player>
                <lottie-player v-if="icon == '/imgs/draw_swords.json'" class="w-full h-full" src="/imgs/draw_swords.json"  background="transparent"  speed="1.4" loop  autoplay></lottie-player>
                <span class="thambi-font-bold text-2xl text-gray-100 dark:text-gray-100 select-none">{{status}}</span>
                <span class="thambi-font-bold text-5xl text-gray-100 dark:text-gray-100 select-none">{{message}}</span>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    emits: ['toggle-modal'],
    props:{
        showing: String,
        message: String,
        icon: String,
        status: String,
    },
    methods: {
       toggleModal(){
           this.$emit('toggle-modal');
       }
    }
}
</script>

<style>

</style>