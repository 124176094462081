<template>
  <div :class="showResignModal" id="ResignModal" class="w-full h-full bg-black bg-opacity-50 flex flex-col absolute z-50 select-none">
    <div class="flex flex-col w-full h-full justify-center items-center">
      <p class="thambi-font-bold text-9xl text-white">ARE YOU SURE?</p>
      <br>
      <p class="thambi-font-bold text-2xl text-white">Resigning will cause you to forfeit the match and accept a loss. Are you sure this is what you want to do?</p>
      <br>
      <div class="w-full flex flex-row gap-8 justify-center">
        <Button text="CANCEL" color="bg-cpc-cyan-50 border-yellow-400 hover:border-yellow-500 hover:bg-cpc-cyan-100 button-shadow-cyan active:bg-yellow-600 z-20" id="CancelResign" />
        <Button text="RESIGN" color="bg-cpc-cyan-50 border-yellow-400 hover:border-yellow-500 hover:bg-cpc-cyan-100 button-shadow-cyan active:bg-yellow-600 z-20" id="VoteResign" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';

export default {
    components: {
        Button,
    },
    props: {
        showResignModal: String,
    }
}
</script>

<style scoped>
    /*Button Shadows */
  .button-shadow{
    box-shadow: 0 -5px 0 0 #cbafff, 0 6px 0 0 #7f43ec, 0 19px 0 0 rgb(0 0 0 / 11%);
  }

  .button-shadow-yellow{
    box-shadow: 0 -5px 0 0 #f6e398, 0 6px 0 0 #f39247, 0 19px 0 0 rgb(0 0 0 / 11%);
  }
  .button-shadow-cyan{
    box-shadow: 0 -5px 0 0 #b8ffdc, 0 6px 0 0 #3c8a64, 0 19px 0 0 rgb(0 0 0 / 11%);
  }
  .button-shadow:hover{
    box-shadow: 0 -5px 0 0 #d5bffd, 0 6px 0 0 #8851ec, 0 19px 0 0 rgb(0 0 0 / 11%);
  }
  .button-shadow-yellow:hover{
    box-shadow: 0 -5px 0 0 #f6e398, 0 6px 0 0 #f39247, 0 19px 0 0 rgb(0 0 0 / 11%);
  }
  .button-shadow-cyan:hover{
    box-shadow: 0 -5px 0 0 #b8ffdc, 0 6px 0 0 #3c8a64, 0 19px 0 0 rgb(0 0 0 / 11%);
  }
  .button-shadow:active{
    transform: translateY(5px);
    box-shadow: 0 -5px 0 0 #d5bffd, 0 6px 0 0 #8851ec, 0 14px 0 0 rgb(0 0 0 / 11%);
  }
  .button-shadow-yellow:active{
    transform: translateY(5px);
    box-shadow: 0 -5px 0 0 #ffb763, 0 6px 0 0 #c6590f, 0 14px 0 0 rgb(0 0 0 / 11%);
  }
  .button-shadow-cyan:active{
    transform: translateY(5px);
    box-shadow: 0 -5px 0 0 #8ff3c1, 0 6px 0 0 #169658, 0 19px 0 0 rgb(0 0 0 / 11%);
  }

  .some-shadow{
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
  }
</style>