const redirect_uri_base = "https://auth.chatplayschess.com"; 

let redirect_uri_endpoint, api_endpoint, appId, cappas_auth_url;

if (window.location.host.includes('localhost') || window.location.host.includes('10.0.0.60')){ 
    // local dev paths
    redirect_uri_endpoint = "/cappas/localcallback";
    //api_endpoint = "http://localhost:9501";
    api_endpoint = "https://api.dev1.chatplayschess.com";
    cappas_auth_url = "https://auth.captain.tv/oauth/authorize";
    appId = "95761e0b6f7646c9";
}else if (window.location.host.includes('dev1')){
    //deployed dev path
    redirect_uri_endpoint = "/cappas/devcallback";
    api_endpoint = "https://api.dev1.chatplayschess.com";
    cappas_auth_url = "https://auth.captain.tv/oauth/authorize";
    appId = "1d6055b7242147da";
}else { 
    //prod paths
    redirect_uri_endpoint = "/cappas/callback";
    api_endpoint = "https://api.chatplayschess.com";
    cappas_auth_url = "https://auth.captain.tv/oauth/authorize";
    appId = "f7c009a030834cc6";
}
const redirect_uri = redirect_uri_base + redirect_uri_endpoint;
const axios = require('axios');

async function fetch_api_token(){
    return await axios.post(`${api_endpoint}/fetch_api_token`)
    .then(result => { return result.data })
    .catch(error => { return error })
}

//Try to get API token, Loop until you can
let api_token; 

get_api_token();

/*
while(api_token === undefined){
    console.log('attempting to get api_token')
     get_api_token();
}
*/ 

async function get_api_token(){ 
    await fetch_api_token().then((result) => {
        if(result && result.success){
            console.log('Received valid game token');
            api_token = result.api_token;
        }else{
            console.log('Error fetching game token:', result);
        }
    })
}
//Try to get API token, Loop until you can

export default {
    async twitchAuthorize(){
        window.location.href=`${cappas_auth_url}?client_id=${appId}&redirect_uri=${redirect_uri}&response_type=code&scope=user:read:email`;
    },
    async getGameState(streamername){
        return await axios.get(`${api_endpoint}/gamestate/${streamername}`)
            .then(result => result.data)
            .catch(error => console.log(error))
    },
    async newGameState(streamername, fen_string, turn, chat_timer){
        let params = {
            streamername: streamername,
            fen_string: fen_string,
            turn: turn,
            active: true,
            chat_timer: chat_timer,
            api_token: api_token,
        } 
        return await axios.post(`${api_endpoint}/newgame/${streamername}`, params)
            .then(result => { return result.data })
            .catch(error => { console.log(error + `Error accessing: ${api_endpoint}/gamestate/${streamername}`) })
    },
    async setGameState(streamername, fen_string, votes, turn, history, flipped, chat_timer, remaining_streamer_time, remaining_chat_time){
        let params = {
            streamername: streamername,
            fen_string: fen_string,
            votes: votes,
            turn: turn,
            history: history,
            flipped: flipped,
            chat_timer: chat_timer,
            remaining_streamer_time: remaining_streamer_time,
            remaining_chat_time: remaining_chat_time,
            api_token: api_token,
        } 

        return await axios.post(`${api_endpoint}/gamestate/${streamername}`, params)
            .then(result => { return result.data })
            .catch(error => { console.log(error + `Error accessing: ${api_endpoint}/gamestate/${streamername}`) })
    },
    async sendEmail(data){
        return await axios.post(`${api_endpoint}/sendemail`, data)
            .then(result => { return result.data })
            .catch(error => { return error })
    },
    async sendBug(data) {
        return await axios.post(`${api_endpoint}/bug`, data)
            .then(result => { return result.data })
            .catch(error => { return error })
    },
    async validate_access(code, captainid){
        let params = {
            access_code: code,
            captainid: captainid
        }
        return await axios.post(`${api_endpoint}/validate_access`, params)
        .then(result => { return result.data })
        .catch(error => { return error })
    },
    async fetch_user(captainid){
        let params = {
            captainid: captainid
        }
        return await axios.post(`${api_endpoint}/fetch_user`, params)
        .then(result => { return result.data })
        .catch(error => { return error })
    },
    async fetch_api_token(){
        return await axios.post(`${api_endpoint}/fetch_api_token`)
        .then(result => { return result.data })
        .catch(error => { return error })
    },
    async getTicketCount(captainid){
        return await axios.get(`${api_endpoint}/ticket_count/${captainid}`)
        .then(result => result.data)
        .catch(error => console.log(error))
    },
    async updateTicketCount(captainid){
        let params = {
            streamername: captainid,
            api_token: api_token,
        }
        return await axios.post(`${api_endpoint}/ticket_count/increase`, params)
        .then(result => { return result.data })
        .catch(error => { return error })
    }
}