<template>
  <div class="bg-gray-900 w-full min-h-screen h-auto flex flex-col">
    <div class="w-full h-32 bg-cpc-bg-grey-600 md:px-8 py-5 flex select-none shadow-2xl z-10">
      <div class="sm:w-1/3 px-4 logo md:px-7 sm:m-auto md:m-0 my-auto">
        <img src="/imgs/chat-plays-chess-logo-1.png" class="max-h-full select-none" >
      </div>   
      <div class="w-1/3 date hidden md:flex flex-col text-center m-auto">
        <div class="rajdhani-font hidden text-text-cyan text-2xl">Open Beta Launch</div>
        <div class="rajdhani-font hidden text-white text-3xl">7/19/2021</div>
      </div>
      <div class="sm:w-1/3 px-2 md:p-2 sm:m-auto my-auto">
        <div class="bg-cpc-grey-300 rounded-xl text-center py-3 px-4 sm:px-5 w-44 sm:w-52 float-right">
          <div class="rajdhani-font text-white text-3xl sm:text-4xl leading-none mt-1">PLAY FREE!</div>
        </div>
      </div>
    </div>
    <div class="w-full h-1/2 bg-cpc-bg-grey-100 py-6 lg:py-28 px-4 lg:pr-24 flex flex-col-reverse lg:flex-row select-none pb-16 lg:pb-52">
      <div class="lg:w-1/2 m-auto">
        <div class="text-white rajdhani-font text-5xl text-center mt-4 lg:mt-0">THE <span class="text-text-green">STREAMER</span> VS <span class="text-text-green">CHAT</span><br>CHESS GAME</div>
        <br class="hidden sm:block">
        <br class="hidden sm:block">
        <div class="text-white text-2xl sm:text-3xl rajdhani-font-light text-center"><span class="rajdhani-font">PLAY FOR FREE!</span></div>
        <br>
        <div class="sm:grid sm:grid-cols-2 sm:px-4 text-white">
          <div class="h-full w-full">
            <div class="text-center h-full grid grid-cols-1 text-3xl px-4 border-b-2 pb-8 sm:pb-0 sm:border-b-0 sm:border-r-2 border-gray-400">
              <span class="rajdhani-font pb-4">Streamers</span>
              <span class="rajdhani-font-light text-2xl">Download the <span class="text-text-green rajdhani-font">Captain Hub</span> to Play!</span>
              <br>
              <a target="_blank" href="https://captain.tv/hub"><button class="rounded-xl bg-text-green py-2"><span class="text-inner-button-grey rajdhani-font text-3xl px-6">GET CAPTAIN HUB</span></button></a>
            </div>
          </div>
          <br class="block sm:hidden">
          <div class="w-full h-full flex">
            <div class="text-center flex flex-col text-3xl px-4">
              <span class="rajdhani-font pb-4">Viewers</span>
              <span class="rajdhani-font-light text-2xl ">Go to your Streamer link at <span class="rajdhani-font">chatplayschess.com<br><span class="text-text-green">/streamername</span></span></span>
              <br>
              <span class="rajdhani-font-light text-2xl">(or play on Twitch with Chat Commands)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-1/2 relative select-none m-auto">
        <img class="w-full" src="imgs/webimage2.png">
        <!-- <img class="w-full" src="imgs/image 49@3x.png">
        <img class="w-1/3 float-right absolute -right-4 -bottom-20 select-none" src="imgs/profile@2x.png"> -->
      </div>
    </div>
    <div class="w-full h-1/2 bg-cpc-bg-grey-400 sm:p-12 select-none">
      <div class="header rajdhani-font text-5xl text-text-green text-center pt-20 pb-16">HOW TO PLAY</div>
      <div class="lg:grid lg:grid-cols-3 justify-center 2xl:px-56">
        <div class="rounded-lg border-2 border-gray-600 relative w-80 m-auto h-full mb-4 lg:mb-0">
          <div class="rounded-full flex items-center justify-center h-10 w-10 text-white text-2xl bg-cpc-bg-grey-100 rajdhani-font absolute left-2 top-3">1</div>
          <div class="graphic pt-10 px-28 justify-center flex">
            <svg width="96" height="68" viewBox="0 0 96 68" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.2177 35.4167L0.0854236 30.6966C-1.01218 12.6928 8.83004 0 8.83004 0L23.0205 15.2619L23.0506 16.2831C18.96 21.8604 16.5854 28.5099 16.2177 35.4167Z" fill="#94DABC"/>
              <path d="M18.2302 49.392L6.51642 53.5191C3.7086 47.8575 1.73355 41.82 0.652499 35.5936C0.535904 34.9121 0.435391 34.2367 0.35498 33.5653L16.1757 38.1889C16.2548 42.0093 16.9485 45.7921 18.2302 49.392Z" fill="#94DABC"/>
              <path d="M24.3253 60.1348L15.9284 67.7416C15.9284 67.7416 11.8255 63.4216 7.76074 55.9173L19.2192 51.8787C20.5385 54.8498 22.2563 57.6274 24.3253 60.1348Z" fill="#94DABC"/>
              <path d="M79.7821 35.4167L95.9144 30.6966C97.012 12.6928 87.1698 0 87.1698 0L72.9794 15.2619L72.9492 16.2831C77.0398 21.8604 79.4144 28.5099 79.7821 35.4167Z" fill="#94DABC"/>
              <path d="M77.77 49.392L89.4838 53.5191C92.2916 47.8575 94.2667 41.82 95.3477 35.5936C95.4643 34.9121 95.5648 34.2367 95.6452 33.5653L79.8245 38.1889C79.7454 42.0093 79.0517 45.7921 77.77 49.392Z" fill="#94DABC"/>
              <path d="M71.6748 60.1348L80.0716 67.7416C80.0716 67.7416 84.1846 63.4216 88.2393 55.9173L76.7809 51.8787C75.4616 54.8498 73.7438 57.6274 71.6748 60.1348Z" fill="#94DABC"/>
              <path d="M65.3607 48.0572C65.3741 48.2789 65.326 48.5 65.2217 48.6961C65.1175 48.8922 64.9611 49.0557 64.7698 49.1686C64.5785 49.2815 64.3598 49.3394 64.1377 49.3359C63.9157 49.3324 63.6989 49.2676 63.5113 49.1488L55.2692 43.8819C55.099 43.7727 54.9591 43.6226 54.8621 43.4452C54.7652 43.2678 54.7144 43.0689 54.7144 42.8667V34.5704C54.7143 34.378 54.7602 34.1884 54.8484 34.0174C54.9365 33.8464 55.0642 33.6989 55.2209 33.5874L63.8429 26.8932C64.0378 26.7559 64.269 26.6793 64.5074 26.6733C64.7457 26.6673 64.9805 26.7321 65.1821 26.8594C65.3836 26.9868 65.5429 27.171 65.6398 27.3888C65.7367 27.6066 65.7669 27.8483 65.7266 28.0833L65.3607 48.0572Z" fill="#94DABC"/>
              <path d="M51.9081 28.4853H30.8506C30.5176 28.4853 30.2476 28.7553 30.2476 29.0884V47.9647C30.2476 48.2978 30.5176 48.5678 30.8506 48.5678H51.9081C52.2411 48.5678 52.5112 48.2978 52.5112 47.9647V29.0884C52.5112 28.7553 52.2411 28.4853 51.9081 28.4853Z" fill="#94DABC"/>
              <path d="M47.9997 8.99188C32.2132 8.99188 19.3696 21.8354 19.3696 37.624C19.3696 53.4125 32.2132 66.254 47.9997 66.254C63.7863 66.254 76.6298 53.4105 76.6298 37.624C76.6298 21.8374 63.7863 8.99188 47.9997 8.99188ZM72.4826 37.624C72.4822 42.4661 71.046 47.1995 68.3555 51.2254C65.6651 55.2513 61.8412 58.3891 57.3675 60.2418C52.8938 62.0945 47.9712 62.5791 43.2221 61.6342C38.473 60.6893 34.1108 58.3574 30.687 54.9333C27.2632 51.5092 24.9316 47.1468 23.9871 42.3976C23.0426 37.6484 23.5275 32.7258 25.3806 28.2523C27.2337 23.7787 30.3718 19.9551 34.3979 17.265C38.4241 14.5749 43.1575 13.139 47.9997 13.139C54.491 13.1465 60.7144 15.7286 65.3042 20.3189C69.8941 24.9091 72.4757 31.1326 72.4826 37.624Z" fill="#94DABC"/>
            </svg>
          </div>
          <div class="text text-white text-center rajdhani-font text-3xl pt-8 pb-16">
            STREAMER hosts <br> the game and <br> controls one side
          </div>
        </div>
        <div class="rounded-lg border-2 border-gray-600 relative w-80 m-auto h-full mb-4 lg:mb-0">
          <div class="rounded-full flex items-center justify-center h-10 w-10 text-white text-2xl bg-cpc-bg-grey-100 rajdhani-font absolute left-2 top-3">2</div>
          <div class="graphic pt-10 px-28 justify-center flex">
            <svg width="57" height="50" viewBox="0 0 57 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.5 0C12.7582 0 0 10.3906 0 23.2143C0 28.75 2.38242 33.817 6.3457 37.8013C4.9541 43.4263 0.300586 48.4375 0.244922 48.4933C0 48.75 -0.0667969 49.1295 0.0779297 49.4643C0.222656 49.7991 0.534375 50 0.890625 50C8.27168 50 13.8047 46.4509 16.5434 44.2634C20.1838 45.6362 24.225 46.4286 28.5 46.4286C44.2418 46.4286 57 36.0379 57 23.2143C57 10.3906 44.2418 0 28.5 0ZM14.25 26.7857C12.2795 26.7857 10.6875 25.1897 10.6875 23.2143C10.6875 21.2388 12.2795 19.6429 14.25 19.6429C16.2205 19.6429 17.8125 21.2388 17.8125 23.2143C17.8125 25.1897 16.2205 26.7857 14.25 26.7857ZM28.5 26.7857C26.5295 26.7857 24.9375 25.1897 24.9375 23.2143C24.9375 21.2388 26.5295 19.6429 28.5 19.6429C30.4705 19.6429 32.0625 21.2388 32.0625 23.2143C32.0625 25.1897 30.4705 26.7857 28.5 26.7857ZM42.75 26.7857C40.7795 26.7857 39.1875 25.1897 39.1875 23.2143C39.1875 21.2388 40.7795 19.6429 42.75 19.6429C44.7205 19.6429 46.3125 21.2388 46.3125 23.2143C46.3125 25.1897 44.7205 26.7857 42.75 26.7857Z" fill="#94DABC"/>
            </svg>
          </div>
          <div class="text text-white text-center rajdhani-font text-3xl pt-8 pb-16">
            CHAT plays the <br> oppossing side and <br> votes on the best <br> move in chat
          </div>
        </div>
        <div class="rounded-lg border-2 border-gray-600 relative w-80 m-auto h-full mb-4 lg:mb-0">
          <div class="rounded-full flex items-center justify-center h-10 w-10 text-white text-2xl bg-cpc-bg-grey-100 rajdhani-font absolute left-2 top-3">3</div>
          <div class="graphic pt-10 px-28 justify-center flex">
            <svg width="47" height="62" viewBox="0 0 47 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="33" width="47" height="29">
              <rect y="33" width="47" height="29" fill="#C4C4C4"/>
              </mask>
              <g mask="url(#mask0)">
              <path d="M6.45611e-06 61.5476C6.80299e-06 57.5798 0.607852 53.6509 1.78884 49.9851C2.96983 46.3193 4.70082 42.9885 6.883 40.1829C9.06518 37.3772 11.6558 35.1517 14.5069 33.6333C17.3581 32.1148 20.4139 31.3333 23.5 31.3333C26.5861 31.3333 29.6419 32.1148 32.4931 33.6333C35.3442 35.1517 37.9348 37.3772 40.117 40.1829C42.2992 42.9885 44.0302 46.3193 45.2112 49.9851C46.3922 53.6509 47 57.5798 47 61.5476L23.5 61.5476L6.45611e-06 61.5476Z" fill="#94DABC"/>
              </g>
              <circle cx="23.4999" cy="24.619" r="13.4286" fill="#94DABC"/>
              <circle cx="23.4998" cy="7.83333" r="7.83333" fill="#94DABC"/>
            </svg>
          </div>
          <div class="text text-white text-center rajdhani-font text-3xl pt-8 pb-16">
            Rotate turns until <br> one side wins!
          </div>
        </div>
      </div>
      <div class="px-4 tracking-tight sm:tracking-normal supporting-text text-center rajdhani-font-light text-white text-4xl pt-12 pb-24">
        Who will get checkmate in CHAT PLAYS CHESS?
        <br>
        <br class="block 2xl:hidden">
        <span class="text-text-green rajdhani-font">CHAT</span> or the <span class="text-text-green rajdhani-font">STREAMER</span>
      </div>
    </div>
    <div class="w-full h-1/3 bg-cpc-bg-grey-200 py-16 md:py-28 lg:px-24 sm:px-12 select-none">
      <div class="flex flex-col sm:flex-row ">
        <div class="px-8 sm:px-0 py-8 sm:py-0 sm:w-1/3">
          <img src="imgs/chat-plays-chess-key-artTwitch.jpg" class="2xl:w-1/2 md:m-auto rounded-xl">
        </div>
        <div class="sm:w-2/3 flex flex-col m-auto px-8 2xl:px-0 sm:px-8 xl:px-16 text-center sm:text-left">
          <span class="text-white text-4xl rajdhani-font mb-8">THE <span class="text-text-green">STREAMER</span> VS <span class="text-text-green">CHAT</span> CHESS GAME!</span>
          <span class="text-white rajdhani-font-light text-2xl mb-4">Play free now!</span>
          <a target="_blank" href="https://captain.tv/hub"><button class="rounded-xl bg-text-green py-2"><span class="text-inner-button-grey rajdhani-font text-3xl px-6">GET CAPTAIN HUB</span></button></a>
        </div>
      </div>
    </div>
    <div class="w-full h-1/6 bg-cpc-bg-grey-100 p-6 footer select-none">
      <div class="flex flex-row sm:float-left gap-5 justify-center">
        <a target="_blank" href="https://twitter.com/ChatPlaysChess">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
          class="bi bi-twitter w-8 h-8 text-gray-400" viewBox="0 0 16 16">
            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
          </svg>
        </a>
        <a target="_blank" href="mailto:chatplayschess@captain.tv">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" 
          class="bi bi-envelope-fill w-8 h-8 text-gray-400" viewBox="0 0 16 16">
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
          </svg>
        </a>
      </div>
      <br class="block sm:hidden">
      <div class="float-right flex">
        <span class="text-gray-400 rajdhani-font-light text-xl whitespace-nowrap sm:whitespace-normal sm:text-2xl mr-3">In partnership with</span>
        <a target="_blank" href="https://captain.tv"> 
          <svg width="163" height="33" viewBox="0 0 163 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.07473 17.1512L2.36673 15.1635C1.90924 7.59002 6.00272 2.24072 6.00272 2.24072L11.9055 8.66582L11.9164 9.09592C10.2159 11.4444 9.2284 14.2436 9.07473 17.1512Z" fill="white"/>
            <path d="M9.9124 23.034L5.04088 24.772C3.87254 22.3885 3.05085 19.8465 2.60131 17.2249C2.5535 16.9378 2.5122 16.654 2.47852 16.3713L9.0572 18.3183C9.09037 19.9265 9.37914 21.5188 9.9124 23.034Z" fill="white"/>
            <path d="M12.4465 27.5572L8.95502 30.7593C8.95502 30.7593 7.24896 28.9421 5.55811 25.7818L10.3231 24.0812C10.8719 25.332 11.5862 26.5014 12.4465 27.5572Z" fill="white"/>
            <path d="M35.5111 17.1512L42.2201 15.1635C42.6765 7.58452 38.5831 2.24072 38.5831 2.24072L32.6814 8.66582L32.6694 9.09592C34.3709 11.4438 35.3585 14.2434 35.5111 17.1512Z" fill="white"/>
            <path d="M34.6743 23.034L39.5458 24.772C40.7138 22.3885 41.5351 19.8465 41.9843 17.2249C42.0332 16.9378 42.0745 16.654 42.1082 16.3713L35.5339 18.3183C35.4993 19.9268 35.2091 21.519 34.6743 23.034Z" fill="white"/>
            <path d="M32.1392 27.5572L35.6317 30.7593C35.6317 30.7593 37.3378 28.9421 39.0286 25.7818L34.2614 24.0812C33.7134 25.3321 32.9994 26.5016 32.1392 27.5572Z" fill="white"/>
            <path d="M29.5139 22.472C29.5199 22.5656 29.5002 22.6591 29.4568 22.7421C29.4135 22.825 29.3483 22.8942 29.2684 22.9418C29.1886 22.9895 29.0972 23.0138 29.0045 23.012C28.9118 23.0102 28.8215 22.9824 28.7434 22.9318L25.3194 20.7142C25.2484 20.6685 25.19 20.6054 25.1496 20.5308C25.1091 20.4562 25.0879 20.3725 25.0879 20.2874V16.7949C25.0878 16.7139 25.1069 16.634 25.1435 16.5621C25.1802 16.4901 25.2334 16.4281 25.2987 16.3813L28.8847 13.5631C28.9656 13.5049 29.0618 13.4724 29.1611 13.4697C29.2603 13.4669 29.3581 13.4941 29.442 13.5477C29.526 13.6014 29.5923 13.679 29.6325 13.7709C29.6728 13.8627 29.6852 13.9646 29.6682 14.0636L29.5139 22.472Z" fill="white"/>
            <path d="M23.8436 14.2329H15.2362C15.0561 14.2329 14.9102 14.3807 14.9102 14.5629V22.3575C14.9102 22.5398 15.0561 22.6875 15.2362 22.6875H23.8436C24.0237 22.6875 24.1696 22.5398 24.1696 22.3575V14.5629C24.1696 14.3807 24.0237 14.2329 23.8436 14.2329Z" fill="white"/>
            <path d="M22.293 6.02698C19.9378 6.02698 17.6355 6.73395 15.6773 8.05849C13.719 9.38302 12.1928 11.2656 11.2916 13.4682C10.3903 15.6708 10.1546 18.0945 10.6141 20.4328C11.0737 22.771 12.2079 24.9188 13.8733 26.6045C15.5388 28.2902 17.6606 29.4381 19.9705 29.9031C22.2805 30.3681 24.6748 30.1292 26.8506 29.2167C29.0264 28.3042 30.8861 26.7591 32.1944 24.7767C33.5027 22.7943 34.2009 20.4637 34.2006 18.0797C34.1972 14.884 32.9415 11.8202 30.7091 9.5606C28.4767 7.30101 25.4499 6.03018 22.293 6.02698ZM32.475 18.0797C32.475 20.1182 31.8779 22.111 30.759 23.8059C29.6402 25.5009 28.05 26.822 26.1895 27.6021C24.3289 28.3822 22.2817 28.5863 20.3065 28.1886C18.3314 27.7909 16.5171 26.8093 15.0931 25.3678C13.6692 23.9264 12.6994 22.0898 12.3065 20.0905C11.9137 18.0911 12.1153 16.0187 12.8859 14.1354C13.6566 12.252 14.9617 10.6423 16.6361 9.50972C18.3105 8.37717 20.2791 7.77268 22.293 7.77268C24.9925 7.77559 27.5807 8.86244 29.4896 10.7947C31.3985 12.727 32.4721 15.347 32.475 18.0797Z" fill="white"/>
            <path d="M56.234 22.44V24.3991C56.234 24.6081 56.1917 24.7544 56.108 24.8391C56.0243 24.9238 55.8689 24.9667 55.6451 24.9667H51.4668C49.9897 24.9667 48.8494 24.5693 48.046 23.7743C47.2426 22.9794 46.8391 21.8354 46.8354 20.3423V15.1195C46.8354 13.625 47.2375 12.4799 48.0416 11.6842C48.8458 10.8886 49.9861 10.4915 51.4625 10.4929H55.6407C56.0326 10.4929 56.229 10.6913 56.2297 11.088V13.0471C56.2297 13.255 56.1873 13.4013 56.1036 13.4871C56.02 13.5729 55.8646 13.6147 55.6407 13.6147H52.0286C51.4491 13.6147 51.0329 13.7423 50.78 13.9975C50.5272 14.2527 50.4012 14.6836 50.4019 15.29V20.1718C50.4019 20.7776 50.5279 21.208 50.78 21.4632C51.0322 21.7184 51.4483 21.846 52.0286 21.846H55.6451C56.037 21.846 56.2333 22.044 56.234 22.44Z" fill="white"/>
            <path d="M63.6655 24.97H62.9918C61.5154 24.97 60.3747 24.5726 59.5699 23.7776C58.765 22.9827 58.3633 21.8376 58.3647 20.3423V15.1195C58.3647 13.625 58.7665 12.4799 59.5699 11.6842C60.3733 10.8886 61.5139 10.4915 62.9918 10.4929H69.4694C69.8802 10.4929 70.0866 10.6909 70.0866 11.088V24.3727C70.0866 24.7709 69.8802 24.9689 69.4694 24.9689H67.1135C66.7223 24.9689 66.5245 24.7709 66.5245 24.3727V23.4366H66.4159C66.2095 23.9476 65.8169 24.3588 65.3194 24.585C64.8044 24.8394 64.2386 24.9711 63.6655 24.97ZM66.5245 19.69V13.9271C66.5245 13.7192 66.4159 13.6147 66.1888 13.6147H63.5525C62.9548 13.6147 62.5339 13.7423 62.2898 13.9975C62.0457 14.2527 61.9243 14.6836 61.9258 15.29V20.1718C61.9258 20.7776 62.0471 21.208 62.2898 21.4632C62.5325 21.7184 62.9534 21.846 63.5525 21.846H64.5903C65.8798 21.846 66.5245 21.1274 66.5245 19.69Z" fill="white"/>
            <path d="M76.1424 28.8299H73.7594C73.3671 28.8299 73.1704 28.6396 73.1704 28.2612V11.088C73.1704 10.6906 73.3667 10.4922 73.7594 10.4929H76.1424C76.5344 10.4929 76.7307 10.6913 76.7314 11.088V12.0252H76.8401C77.045 11.5141 77.4365 11.1024 77.9333 10.8757C78.4486 10.6223 79.0143 10.4914 79.5872 10.4929H80.739C82.1778 10.4929 83.2663 10.89 84.0045 11.6842C84.7427 12.4784 85.1122 13.6235 85.1129 15.1195V20.3423C85.1129 21.8369 84.7434 22.9816 84.0045 23.7765C83.2655 24.5715 82.1771 24.9693 80.739 24.97H79.5915C79.0185 24.9717 78.4527 24.8404 77.9376 24.5861C77.4412 24.3595 77.0498 23.9483 76.8444 23.4377H76.7358V28.2623C76.7314 28.6396 76.5347 28.8299 76.1424 28.8299ZM78.6668 21.846H79.9001C80.4985 21.846 80.9238 21.7184 81.1759 21.4632C81.428 21.208 81.5544 20.7776 81.5551 20.1718V15.29C81.5551 14.6843 81.4287 14.2535 81.1759 13.9975C80.9231 13.7416 80.4978 13.614 79.9001 13.6147H78.6668C77.3758 13.6147 76.7307 14.3338 76.7314 15.7718V19.69C76.7314 21.1281 77.3765 21.8468 78.6668 21.846Z" fill="white"/>
            <path d="M88.2285 13.6147H87.107C86.8821 13.6147 86.7278 13.5762 86.6441 13.5047C86.5605 13.4332 86.5181 13.277 86.5181 13.0504V11.088C86.5181 10.6905 86.7144 10.4922 87.107 10.4929H88.2285C88.2705 10.4988 88.3132 10.4947 88.3534 10.481C88.3935 10.4673 88.43 10.4444 88.4599 10.414C88.4899 10.3836 88.5124 10.3466 88.5258 10.3059C88.5392 10.2652 88.543 10.2219 88.5371 10.1794V7.47999C88.5371 7.08326 88.7428 6.88452 89.1543 6.88379H91.5091C91.9018 6.88379 92.0981 7.08252 92.0981 7.47999V10.1794C92.0981 10.3884 92.2068 10.4929 92.435 10.4929H94.6224C95.0143 10.4929 95.2103 10.6913 95.2103 11.088V13.0471C95.2103 13.255 95.169 13.4013 95.0842 13.4871C94.9995 13.5729 94.8463 13.6147 94.6224 13.6147H92.435C92.21 13.6147 92.0981 13.7247 92.0981 13.9271V20.1971C92.0981 20.7838 92.2289 21.2047 92.4904 21.4599C92.7534 21.7151 93.1815 21.8427 93.7802 21.8427H94.9028C95.2954 21.8427 95.4917 22.0414 95.4917 22.4389V24.398C95.4917 24.607 95.4494 24.7533 95.3657 24.838C95.282 24.9227 95.1266 24.9656 94.9028 24.9656H93.1924C91.7145 24.9656 90.5692 24.5681 89.7563 23.7732C88.9435 22.9783 88.5371 21.8416 88.5371 20.3632V13.9271C88.5431 13.8847 88.5392 13.8414 88.5258 13.8007C88.5125 13.76 88.4899 13.7231 88.4599 13.6928C88.43 13.6625 88.3935 13.6396 88.3533 13.6261C88.3131 13.6125 88.2704 13.6086 88.2285 13.6147Z" fill="white"/>
            <path d="M102.839 24.97H102.165C100.689 24.97 99.5481 24.5726 98.7432 23.7776C97.9383 22.9827 97.5366 21.838 97.5381 20.3434V15.1195C97.5381 13.625 97.9398 12.4799 98.7432 11.6842C99.5466 10.8886 100.687 10.4915 102.165 10.4929H108.643C109.053 10.4929 109.26 10.6909 109.26 11.088V24.3727C109.26 24.7709 109.053 24.9689 108.643 24.9689H106.288C105.896 24.9689 105.699 24.7709 105.699 24.3727V23.4366H105.59C105.385 23.947 104.993 24.3581 104.497 24.585C103.981 24.8401 103.413 24.9718 102.839 24.97ZM105.699 19.69V13.9271C105.699 13.7192 105.59 13.6147 105.362 13.6147H102.726C102.128 13.6147 101.707 13.7423 101.464 13.9975C101.222 14.2527 101.099 14.6839 101.099 15.29V20.1718C101.099 20.7776 101.221 21.208 101.464 21.4632C101.707 21.7184 102.128 21.846 102.726 21.846H103.765C105.053 21.846 105.698 21.1274 105.699 19.69Z" fill="white"/>
            <path d="M112.484 8.05089V5.58139C112.484 5.20299 112.671 5.01379 113.046 5.01379H115.485C115.858 5.01379 116.045 5.19419 116.045 5.55279V8.05089C116.045 8.43039 115.858 8.61849 115.485 8.61849H113.046C112.671 8.61849 112.484 8.43039 112.484 8.05089ZM112.484 24.3727V11.088C112.484 10.6905 112.671 10.4922 113.046 10.4929H115.456C115.849 10.4929 116.045 10.6909 116.045 11.088V24.3727C116.045 24.6191 116.004 24.7797 115.919 24.8556C115.791 24.9425 115.637 24.9814 115.484 24.9656H113.072C112.681 24.97 112.484 24.7709 112.484 24.3727Z" fill="white"/>
            <path d="M122.243 24.97H119.859C119.467 24.97 119.271 24.772 119.271 24.3738V11.088C119.271 10.6906 119.467 10.4922 119.859 10.4929H122.243C122.636 10.4929 122.832 10.6909 122.832 11.088V12.0252H122.94C123.352 11.0037 124.268 10.4929 125.689 10.4929H126.586C129.521 10.4929 130.989 12.0351 130.988 15.1195V24.3727C130.988 24.7709 130.783 24.9689 130.372 24.9689H128.016C127.624 24.9689 127.427 24.7709 127.427 24.3727V15.29C127.427 14.6843 127.301 14.2535 127.049 13.9975C126.797 13.7416 126.372 13.614 125.773 13.6147H124.764C123.473 13.6147 122.828 14.3338 122.828 15.7718V24.3727C122.832 24.7709 122.636 24.97 122.243 24.97Z" fill="white"/>
            <path d="M137.048 20.8813V24.3727C137.048 24.7709 136.871 24.9689 136.517 24.9689H134.017C133.642 24.9689 133.457 24.7709 133.457 24.3727V20.9099C133.457 20.5315 133.642 20.3423 134.017 20.3423H136.517C136.871 20.3423 137.048 20.522 137.048 20.8813Z" fill="white"/>
            <path d="M139.963 13.6147H138.843C138.62 13.6147 138.464 13.5762 138.38 13.5047C138.297 13.4332 138.254 13.277 138.254 13.0504V11.088C138.254 10.6905 138.451 10.4922 138.843 10.4929H139.963C140.005 10.4988 140.047 10.4947 140.088 10.481C140.128 10.4673 140.164 10.4444 140.194 10.414C140.224 10.3836 140.247 10.3466 140.26 10.3059C140.273 10.2652 140.277 10.2219 140.271 10.1794V7.47999C140.271 7.08326 140.477 6.88452 140.888 6.88379H143.243C143.637 6.88379 143.832 7.08289 143.832 7.47999V10.1794C143.832 10.3884 143.941 10.4929 144.169 10.4929H146.357C146.749 10.4929 146.946 10.6913 146.946 11.088V13.0471C146.946 13.255 146.903 13.4013 146.819 13.4871C146.736 13.5729 146.582 13.6147 146.357 13.6147H144.169C143.945 13.6147 143.832 13.7247 143.832 13.9271V20.1971C143.832 20.7838 143.963 21.2047 144.226 21.4599C144.488 21.7151 144.918 21.8427 145.515 21.8427H146.637C147.029 21.8427 147.225 22.0414 147.226 22.4389V24.398C147.226 24.607 147.184 24.7533 147.1 24.838C147.016 24.9227 146.861 24.9656 146.637 24.9656H144.929C143.451 24.9656 142.306 24.5681 141.494 23.7732C140.682 22.9783 140.275 21.8416 140.273 20.3632V13.9271C140.279 13.8844 140.276 13.8409 140.262 13.8001C140.249 13.7592 140.226 13.7221 140.196 13.6918C140.165 13.6614 140.129 13.6386 140.088 13.6252C140.048 13.6119 140.005 13.6082 139.963 13.6147Z" fill="white"/>
            <path d="M148.939 10.4929H151.406C151.78 10.4929 152.004 10.6913 152.079 11.088L154.491 21.758H154.687L157.043 11.088C157.115 10.6906 157.349 10.4922 157.744 10.4929H160.212C160.604 10.4929 160.744 10.6913 160.631 11.088L157.098 24.3441C157.026 24.7614 156.772 24.9697 156.338 24.9689H152.785C152.393 24.9689 152.139 24.7607 152.025 24.3441L148.518 11.088C148.406 10.6909 148.547 10.4929 148.939 10.4929Z" fill="white"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>