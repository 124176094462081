<template>
    <button type="button" :class="color" class="place-self-center sm:float-right px-4 py-3 sm:flex sm:px-5 sm:py-4 rounded-2xl focus:outline-none hover:text-white text-white text-xl">
      <div class="my-auto flex flex-row h-full items-center justify-center">
        <slot></slot>
        <span v-if="text"  class="text-2xl thambi-font-bold text-gray-800">{{text}}</span>
      </div>
    </button>
</template>

<script>
export default {
    props:{
        text: String,
        color: String,
    }
}
</script>

<style>

</style>