<template>
    <div class="flex flex-row w-min space-x-4 items-center content-evenly h-full justify-content" id="nav">
        <button @click="openBugsModal" class="has-tooltip flex items-center focus:outline-none justify-center text-gray-400 hover:text-white dark:hover:text-white dark:text-gray-400 h-10 w-10 ease-in rounded">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-44">Bug Report</span>
            <svg 
                class="w-10 h-10"
                width="49" 
                height="49" 
                viewBox="0 0 49 49" 
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M48.9988 27.6486C48.9531 29.3167 47.5425 30.625 45.8738 30.625H40.5781V32.1562C40.5781 34.2487 40.1109 36.2316 39.2765 38.008L45.0405 43.772C46.2364 44.968 46.2364 46.907 45.0405 48.103C43.8444 49.299 41.9054 49.2989 40.7094 48.103L35.471 42.8646C33.1023 44.7857 30.0843 45.9375 26.7968 45.9375V22.5859C26.7968 21.9517 26.2826 21.4375 25.6484 21.4375H23.3515C22.7173 21.4375 22.2031 21.9517 22.2031 22.5859V45.9375C18.9156 45.9375 15.8976 44.7857 13.5289 42.8646L8.29052 48.103C7.09442 49.299 5.15538 49.2989 3.95937 48.103C2.76347 46.907 2.76347 44.968 3.95937 43.772L9.72338 38.008C8.88904 36.2316 8.42182 34.2487 8.42182 32.1562V30.625H3.12618C1.4575 30.625 0.0469324 29.3167 0.00118633 27.6486C-0.0463781 25.9179 1.34247 24.5 3.06244 24.5H8.42182V18.8779L3.95937 14.4155C2.76347 13.2195 2.76347 11.2804 3.95937 10.0844C5.15547 8.88842 7.09442 8.88842 8.29052 10.0844L13.5185 15.3125H35.4813L40.7093 10.0845C41.9054 8.88852 43.8443 8.88852 45.0404 10.0845C46.2363 11.2805 46.2363 13.2196 45.0404 14.4156L40.5781 18.8779V24.5H45.9375C47.6574 24.5 49.0463 25.9179 48.9988 27.6486ZM24.5957 0C18.6758 0 13.8769 4.79893 13.8769 10.7187H35.3144C35.3144 4.79893 30.5155 0 24.5957 0Z" fill="currentColor"/>
            </svg>
        </button>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20" id="ShowResignModal">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-48">Resign</span>
            <svg xmlns="http://www.w3.org/2000/svg" 
                width="16" 
                height="16" 
                fill="currentColor" 
                class="bi bi-flag-fill w-6 h-6 text-gray-800" viewBox="0 0 16 16">
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
            </svg>
        </GameButton>
        <GameButton color="px-3 py-3 has-tooltip game-button-shadow border-purple-400 hover:border-purple-500 hover:bg-purple-300 active:bg-purple-600 z-20" id="FlipBoard">
            <span class="tooltip rounded shadow-lg px-2 py-1 bg-cpc-grey-400 -ml-44">Flip Board</span>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                class="w-6 h-6 text-gray-800" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
            </svg>
        </GameButton>
    </div>
</template>

<script>
import GameButton from "@/components/GameButton"

export default {
    components: { 
        GameButton,
    },
    emits: ['toggle-bug-modal'],
    methods:{ 
        openBugsModal(){
            this.$emit('toggle-bug-modal');
        },
    }

}
</script>

<style>
    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    .tooltip {
        @apply invisible;
        @apply absolute;
    }
    .has-tooltip:hover .tooltip {
        @apply visible;
        @apply z-50;
    }
</style>