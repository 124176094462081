<template>
  <div :class="showing" class="w-full h-screen bg-black bg-opacity-50 absolute z-30">
      <div class="relative w-full h-full m-auto container flex items-center justify-center p-36 py-6">
        <div class="bg-cpc-grey-200 h-full overflow-y-auto scrollbar-thin scrollbar-thumb-green-400 scrollbar-track-green-100 scrollbar-thumb-rounded dark:bg-cpc-grey-200 border-4 border-cpc-grey-200 dark:border-cpc-grey-200 rounded-3xl w-1/2 p-6 relative">
            <div class="w-8 h-8 absolute right-8 top-8 cursor-pointer text-gray-200 dark:text-gray-200" id="closeSettings" @click="toggleModal">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div>
            <div class="ubuntu-font text-4xl font-semibold text-cpc-cyan-50 dark:text-cpc-cyan-50 text-center select-none">Settings</div>
            <div class="mt-8 ">
                <div class="text-2xl text-white dark:text-gray-200 ubuntu-font select-none text-center">Chat Timer</div>
                <div class="text-base text-white dark:text-gray-300 ubuntu-font select-none text-center mt-2 ">Select how many seconds you want chat to have to vote</div>
                <div class="flex w-full justify-center mt-3">
                    <div class="flex border-2 border-cpc-cyan-50 dark:border-cpc-cyan-50 rounded-lg shadow w-1/6 relative">
                        <select id="chat_timer" class="mt-1 block w-full z-10 py-2 px-3 focus:outline-none ubuntu-font appearance-none bg-transparent text-white dark:text-gray-300" @change="updateTimer($event.target.value)">
                            <option class="text-black dark:text-black" value="20">20</option>
                            <option class="text-black dark:text-black" value="30">30</option>
                            <option class="text-black dark:text-black" value="45">45</option>
                            <option class="text-black dark:text-black" value="60">60</option>
                        </select>
                        <svg fill="currentColor" stroke="currentColor" class="w-6 h-6 text-gray-800 dark:text-gray-300 place-self-center absolute right-1 select-none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg> 
                    </div>
                </div>
            </div>
            <div class="mt-8 ">
                <div class="text-2xl text-white dark:text-gray-200 ubuntu-font select-none text-center">Game Clock</div>
                <div class="text-base text-white dark:text-gray-300 ubuntu-font select-none text-center mt-2 ">Select how long of a game you want to play</div>
                <div class="flex w-full justify-center mt-3">
                    <div class="flex border-2 border-cpc-cyan-50 dark:border-cpc-cyan-50 rounded-lg shadow w-2/6 relative">
                        <select id="game_clock" class="mt-1 block w-full z-10 py-2 px-3 focus:outline-none ubuntu-font appearance-none bg-transparent text-white dark:text-white" @change="setGameClock($event.target.value)">
                            <option class="text-black dark:text-black" value="1">1 minute game</option>
                            <option class="text-black dark:text-black" value="3">3 minute game</option>
                            <option class="text-black dark:text-black" value="5">5 minute game</option>
                            <option class="text-black dark:text-black" value="10">10 minute game</option>
                            <option class="text-black dark:text-black" value="30">30 minute game</option>
                            <option class="text-black dark:text-black" value="60">60 minute game</option>
                            <option class="text-black dark:text-black" value="999">Unlimited time</option>
                        </select>
                        <svg fill="currentColor" stroke="currentColor" class="w-6 h-6 text-gray-800 dark:text-gray-300 place-self-center absolute right-1 select-none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg> 
                    </div>
                </div>
            </div>
            <div class="mt-8 ">
                <div class="text-2xl text-white dark:text-gray-200 ubuntu-font select-none text-center">Notification Options</div>
                <div class="text-base text-white dark:text-gray-300 ubuntu-font select-none text-center mt-2 ">Choose if you'll want to show social notifications for votes</div>
                <div class="flex flex-wrap w-full px-10 mt-6">
                    <div class="w-1/5">
                        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input @click="updateShowNotification" type="checkbox" name="toggle_notifications" id="toggle_notifications" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"/>
                            <label for="toggle_notifications" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                        </div>
                    </div>
                    <div class="w-4/5 mb-4">
                        <label for="toggle_notifications" class=" text-white ubuntu-font dark:text-gray-300 whitespace-nowrap ">Show notifications for votes and illegal moves</label>
                    </div>
                </div>
            </div>
            <div class="mt-8 ">
                <div class="text-2xl text-white dark:text-gray-200 ubuntu-font select-none text-center">Board/Piece Theme</div>
                <div class="text-base text-white dark:text-gray-300 ubuntu-font select-none text-center mt-2 ">Switch through themes for the board and piece</div>
                <div class="flex w-full justify-center items-center mt-3">
                   <span class="ubuntu-font text-white">Board Theme:&nbsp;</span> <div class="flex border-2 border-cpc-cyan-50 dark:border-cpc-cyan-50 rounded-lg shadow w-2/6 relative">
                        <select id="board_theme" class="mt-1 block w-full z-10 py-2 px-3 focus:outline-none ubuntu-font appearance-none bg-transparent text-white dark:text-gray-300" @change="changeBoardTheme($event.target.value)">
                            <option v-for="(themeValue, themeName) in themeLibrary" :key="themeValue" class="text-black dark:text-black" :value="themeValue">{{themeName}}</option>
                        </select>
                        <svg fill="currentColor" stroke="currentColor" class="w-6 h-6 text-gray-300 dark:text-gray-300 place-self-center absolute right-1 select-none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
                <div class="flex w-full justify-center items-center mt-3">
                    <span class="ubuntu-font text-white">Piece Theme:&nbsp;</span> <div class="flex border-2 border-cpc-cyan-50 dark:border-cpc-cyan-50 rounded-lg shadow w-2/6 relative">
                        <select id="piece_theme" class="mt-1 block w-full z-10 py-2 px-3 focus:outline-none ubuntu-font appearance-none bg-transparent text-white dark:text-gray-300" @change="changePieceTheme($event.target.value)">
                            <option v-for="(themeValue, themeName) in themeLibrary" :key="themeValue" class="text-black dark:text-black" :value="themeValue">{{themeName}}</option>
                        </select>
                        <svg fill="currentColor" stroke="currentColor" class="w-6 h-6 text-gray-300 dark:text-gray-300 place-self-center absolute right-1 select-none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
            </div>
            <div class="mt-8 ">
                <div class="text-2xl text-white dark:text-gray-200 ubuntu-font select-none text-center">Highlight Options</div>
                <div class="text-base text-white dark:text-gray-300 ubuntu-font select-none text-center mt-2 ">Choose how you want the board to be highlighted <br> (You'll need to refresh for these to take effect)</div>
                <div class="flex flex-wrap w-full px-10 mt-6">
                    <div class="w-1/5">
                        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input type="checkbox" name="toggle_legal" id="toggle_legal" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"/>
                            <label for="toggle_legal" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                        </div>
                    </div>
                    <div class="w-4/5 mb-4">
                        <label for="toggle" class=" text-white ubuntu-font dark:text-gray-300 ">Highlight the legal moves for pieces.</label>
                    </div>
                    <div class="w-1/5">
                        <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input type="checkbox" name="toggle_highlight" id="toggle_highlight" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"/>
                            <label for="toggle_highlight" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                        </div>
                    </div>
                    <div class="w-4/5">
                        <label for="toggle" class="text-white ubuntu-font dark:text-gray-300 ">Highlight the previously made moves.</label>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <div class="text-2xl text-white dark:text-gray-200 ubuntu-font select-none text-center">Log Out</div>
                <div class="text-base text-white dark:text-gray-300 ubuntu-font select-none text-center mt-2 ">Sometimes if the games bugged, logging out will help!</div>
                <div class="flex w-full justify-center mt-3">
                    <button class="focus:outline-none" id="LogOut" @click="logOut">
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            width="16" height="16" fill="currentColor" 
                            class="bi bi-box-arrow-left h-8 w-8 text-gray-400 hover:text-white"  
                            viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"/>
                            <path fill-rule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <!-- Need to finish properly configuring this before it can be released
            <div class="mt-8 ">
                <div class="text-2xl text-gray-700 dark:text-gray-200 ubuntu-font select-none text-center">Mirror Link</div>
                <div class="text-base text-gray-700 dark:text-gray-300 ubuntu-font select-none text-center mt-2 ">Paste the link below on a new <b>browser source</b> of your streaming software.</div>
                <div class="flex w-full justify-center mt-3">
                    <button class="rounded-lg border-2 border-purple-600 dark:border-cpc-cyan-50 px-8 p-2 focus:outline-none active:bg-purple-300 focus:bg-purple-300 text-purple-800 dark:text-purple-300 dark:focus:text-purple-800 dark:active:text-purple-800 ">
                        <span class="ubuntu-font text-lg">Copy link</span>
                    </button>
                </div>
            </div>
            -->
        </div>
      </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Router from '@/router';

export default {
    emits: ['toggle-modal', 'update-timer', 'set-game-clock', 'change-color-scheme', 'update-show-notification', 'change-board-theme', 'change-piece-theme'],
    props: {
        showing: String,
    },
    setup(){

        let themeLibrary = {};
        themeLibrary['Alpha'] = 'alpha';
        themeLibrary['Chess 24'] = 'chess24';
        themeLibrary['Dilena'] = 'dilena';
        themeLibrary['Leipzig'] = 'leipzig';
        themeLibrary['Metro'] = 'metro';
        themeLibrary['Symbol'] = 'symbol';
        themeLibrary['Wikipedia'] = 'wikipedia';
        themeLibrary['USCF'] = 'uscf';
        themeLibrary['Default'] = 'cpc';
        

        function logOut(){
            localStorage.clear();
            Router.replace({name: 'Choose Game'});
        }

        return{ 
            logOut,
            themeLibrary
        }
    },
    mounted(){
        if(localStorage.getItem('chat_timer') !== null){
            let chat_timer = localStorage.getItem('chat_timer');
            let selectEl = document.getElementById('chat_timer');
            selectEl.value = chat_timer;
        }

        if(localStorage.getItem('game_clock') !== null){
            let game_clock = localStorage.getItem('game_clock');
            let selectEl = document.getElementById('game_clock');
            selectEl.value = game_clock;
        }

        let highlight_move_toggle = document.getElementById('toggle_highlight');
        let highlight_legal_toggle = document.getElementById('toggle_legal');
        let update_notification_toggle = document.getElementById('toggle_notifications');
        let board_theme_switch = document.getElementById('board_theme');
        let piece_theme_switch = document.getElementById('piece_theme');

        highlight_move_toggle.onchange = function(e){
            //console.log(e.target.checked);
            localStorage.setItem('highlight_move', e.target.checked);
            Swal.fire('Refresh Required!', "You'll need to refresh for this change to take effect", 'info');
        }

        highlight_legal_toggle.onchange = function(e){
            //console.log(e.target.checked);
            localStorage.setItem('highlight_legal', e.target.checked);
            Swal.fire('Refresh Required!', "You'll need to refresh for this change to take effect", 'info');
        }

        update_notification_toggle.onchange = function(e){
            localStorage.setItem('show_notifications', e.target.checked);
        }

        if(localStorage.getItem('highlight_move') !== null){
            highlight_move_toggle.checked = JSON.parse(localStorage.getItem('highlight_move'));
        }else{
            highlight_move_toggle.checked = true;
        }
        if(localStorage.getItem('highlight_legal') !== null){
            highlight_legal_toggle.checked = JSON.parse(localStorage.getItem('highlight_legal'));
        }else{
            highlight_legal_toggle.checked = true;
        }
        if(localStorage.getItem('show_notifications') !== null){
            update_notification_toggle.checked = JSON.parse(localStorage.getItem('show_notifications'))
        }else{
            update_notification_toggle.checked = true;
            localStorage.setItem('show_notifications', true);
        }
        if(localStorage.getItem('board_theme') !== null){
            board_theme_switch.value = localStorage.getItem('board_theme')
        }else{
            board_theme_switch.value = 'cpc';
            localStorage.setItem('board_theme', 'cpc');
        }
        if(localStorage.getItem('piece_theme') !== null){
            piece_theme_switch.value = localStorage.getItem('piece_theme')
        }else{
            piece_theme_switch.value = 'cpc';
            localStorage.setItem('piece_theme', 'cpc');
        }
    },
    methods: {
        toggleDarkMode(){
            this.$emit('change-color-scheme');
        },
        toggleModal(){
            this.$emit('toggle-modal');
        },
        updateTimer(val){
            localStorage.setItem('chat_timer', val);
            this.$emit('update-timer', val);
        },
        setGameClock(val){
            localStorage.setItem('game_clock', val);
            this.$emit('set-game-clock', val);
            Swal.fire('Restart Required!', "You'll need to restart the game for this change to take effect", 'info');
        },
        updateShowNotification(){
            this.$emit('update-show-notification');
        },
        changeBoardTheme(val){
            localStorage.setItem('board_theme', val);
            this.$emit('change-board-theme', val);
        },
        changePieceTheme(val){
            localStorage.setItem('piece_theme', val);
            this.$emit('change-piece-theme', val);
        }
    },
}
</script>

<style>
.toggle-checkbox:checked {
  @apply right-0;
  @apply bg-cpc-cyan-100;
  @apply border-teal-500;
  right: 0;
  /* border-color: #68D391; */
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-cpc-cyan-100;
  @apply border-teal-500;
  /* background-color: #68D391; */
}
</style>