<template>
  <div class="">
  </div>
</template>

<script>
import API from '@/lib/API.js';
import Router from '@/router';
export default {
    setup(){
        if(localStorage.getItem('twitch_channel' !== null)){
            let twitch_channel = localStorage.getItem('twitch_channel');
            let mode = 'twitch';
            Router.push({name: 'Game', params: {twitch_channel: twitch_channel, mode: mode}}) 
        }else{
            API.twitchAuthorize();
        }
    }
}
</script>

<style>

</style>