<template>
  <div class="h-full min-h-screen w-full bg-cpc-grey-200">
      <div class="flex flex-col p-9">
        <div id="logo_banner" class="w-full flex content-center justify-center select-none">
            <img src="/imgs/chat-plays-chess-logo-1.png" class="w-80">
        </div>
        <div class="flex flex-col w-full pt-10">
            <div class="text-white baloo-font text-6xl text-center pb-10 font-bold select-none">How do you want to play?!</div>
            <div class="grid grid-cols-3 justify-center text-center">
                <div class="flex justify-center">
                    <GameCard cardTitle="Chat vs Streamer" buttonText="PLAY" chooseSide buttonRoute="Game">
                        <template v-slot:left-icon>
                            <img src="/imgs/groupofwhitepawns.png" class="w-1/2">
                        </template>
                        <template v-slot:right-icon>
                            <img src="/imgs/blackqueen.png" class="w-1/2 float-right">
                        </template>
                    </GameCard>
                </div>
                <div class="flex justify-center">
                    <GameCard cardTitle="vs Bots" buttonText="Coming Soon!">
                        <template v-slot:left-icon>
                            <img src="/imgs/whitequeenwithpawns.png" class="w-1/2">
                        </template>
                        <template v-slot:right-icon>
                            <img src="/imgs/greenpawns.png" class="w-1/2 float-right">
                        </template>
                    </GameCard>
                </div>
                <div class="flex justify-center">
                    <GameCard cardTitle="Chat vs Chat" buttonText="Coming Soon!">
                        <template v-slot:left-icon>
                            <img src="/imgs/whitequeenwithpawns.png" class="w-1/2">
                        </template>
                        <template v-slot:right-icon>
                            <img src="/imgs/blackqueenwithpawns.png" class="w-1/2 float-right">
                        </template>
                    </GameCard>
                </div>
            </div>
        </div>
      </div>

  </div>
</template>

<script>
import GameCard from '../components/GameCard.vue';
import API from '@/lib/API.js';

//import GroupOfWhitePawns from '../components/GroupOfWhitePawns.vue';
//import BlackQueen from '../components/BlackQueen.vue';
//import WhiteQueenWithPawns from '../components/WhiteQueenWithPawns.vue';
//import GreenPawns from '../components/GreenPawns.vue';
//import BlackQueenWithPawns from '../components/BlackQueenWithPawns.vue';

export default {
    components: {
        //GroupOfWhitePawns,
        GameCard,
        //BlackQueen,
        //WhiteQueenWithPawns,
        //GreenPawns,
        //BlackQueenWithPawns,
    },
    setup(){
        let twitch_channel;
        let mode;

        if(localStorage.getItem('twitch_channel') !== null && twitch_channel === undefined){
            twitch_channel = localStorage.getItem('twitch_channel').toLowerCase();
            mode = 'twitch'; //hardcoded for now?
        }else{
            console.log('couldnt get saved info or routed info. forcing reauth');
            API.twitchAuthorize();
        }

        return {
            twitch_channel,
            mode,
        }
    }
}
</script>

<style scoped>
  
</style>