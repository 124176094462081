<template>
  <router-view/>
</template>

<script>
export default {
  watch: {
    '$route' (to){
      document.title = to.meta.title || "Chat Plays Chess"
    },
  },
  name: 'App',
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
}
#timer{
  /*margin: 4px;*/
  display: flex;
  background-color: #1affee;
  border-bottom: 4px #00c7c2 solid;
  border-top: 4px #a0fff8 solid;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  transition-property: width;
  /*transition-timing-function: linear;*/
}
.baloo-font{
    font-family: 'Baloo Bhaina';
}
.baloo-font-bold{
    font-family: 'Baloo Bhaina';
    font-weight: 700;
}
.thambi-font{
  font-family: 'Baloo Thambi';
  font-weight: 400;
}
.thambi-font-bold{ 
  font-family: 'Baloo Thambi';
  font-weight: 700;
}
.fredokaone-font{
  font-family: "Fredoka One";
}
.lato-font{
  font-family: "Lato";
}
.lato-font-bold{ 
  font-family: "Lato";
  font-weight: 700;
}
.ubuntu-font{
  font-family: 'Ubuntu';
}
.rajdhani-font{
  font-family: 'Rajdhani';
  font-weight: 700;
}
.rajdhani-font-light{
  font-family: 'Rajdhani';
  font-weight: 400;
}

.boxHome {
  background-image: url('/imgs/boxHome.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}
.queenPiece{
  background-image: url('/imgs/queenPiece.svg');
  background-repeat: no-repeat;
  background-position: center;
}
</style>
