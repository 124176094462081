<template>

    <div
        :class="showing"
        class="w-96 h-96 py-5 bg-purple-400 bg-opacity-90 dark:bg-opacity-90 dark:bg-gray-400 z-20 movesModal border-4 rounded-lg border-purple-200 dark:border-gray-200"
    >
        <div class="h-full flex flex-col ubuntu-font font-semibold text-white dark:text-gray-700 text-left">
            <div class="relative flex flex-row justify-center">
                <h3 class="text-2xl text-gray-50 font-black select-none fredokaone-font font-bold some-shadow whitespace-pre mb-3">Moves History</h3>
                <div class="w-8 h-8 absolute right-0 top-0 cursor-pointer text-white" id="closeMovesModal" @click="toggleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <div class="h-px flex flex-grow justify-center w-full">
                <div class="text-center h-full w-full text-white baloo-font text-2xl overflow-y-scroll scrollbar-thin scrollbar-thumb-purple-500 scrollbar-track-purple-300 scrollbar-thumb-rounded">
                    <span v-html="moves"></span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    emits: ['toggle-modal'],
    props: {
		showing: String,
		moves: String,
	},
    setup(){

    },
    mounted(){

    },
    methods: {
        toggleModal() {
			this.$emit('toggle-modal')
		},
    }
}
</script>

<style>
.movesModal {
	padding: 1em;
	position: absolute;
	bottom: 1em;
	right: 1em;
}
</style>