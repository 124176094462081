import { createRouter, createWebHistory } from 'vue-router';
import Game from '../views/Game.vue';
import Twitch from '../views/Twitch.vue';
import TwitchSuccess from '../views/TwitchSuccess';
import LiveChessBoard from '../views/LiveChessBoard';
import ComingSoon from '../views/ComingSoon';
import LandingPage from '../views/LandingPage.vue';
import Game2 from '../views/Game2';
import Game3 from '../views/Game3';
import GameInterstitial from '../views/GameInterstitial.vue';

const routes = [
  {
    path: '/play',
    alias: '/game',
    name: 'Game',
    component: Game2
  },
  {
    path: '/',
    name: 'Landing',
    component: LandingPage
  },
  {
    path: '/twitch',
    name: 'Twitch Connect',
    component: Twitch,
  },
  {
    path: '/twitch_success/access_token/:access_token/refresh_token/:refresh_token/user_id/:user_id/user_name/:user_name/profilepicture/:profile_picture/defaultpicture/:default_picture',
    name: 'Successful Twitch Auth',
    component: TwitchSuccess,
  },
  {
    path: '/play/:streamername',
    alias: '/:streamername',
    name: 'Live Chess Board',
    component: LiveChessBoard,
  },
  {
    path: '/choose_game',
    name: 'Choose Game',
    component: GameInterstitial,
  },
  {
    path: '/hype',
    alias: '/soon',
    name: 'Coming Soon',
    component: ComingSoon,
  },
  {
    path: '/game2',
    alias: '/dev',
    name: 'Dev Testing',
    component: Game,
  },
  {
    path: '/game3',
    alias: '/test',
    name: 'Dev Testing',
    component: Game3,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
